import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import * as i18nIsoCountries from "i18n-iso-countries";
import { AuthService } from "src/app/auth/services/auth.service";
import { CommonControllerService } from "src/app/services/common-controller.service";
import { DataEntryControllerService } from "src/app/services/data-entry-controller.service";
import { CommonInfoFolder } from "../../Interfaces/commonInfoFolder";
import { GHDataEntrySearchModalComponent } from "../gh-data-entry-search-modal/gh-data-entry-search-modal.component";
import {
  DateFormat,
  FieldComparationOption,
  FieldComparationStyle,
  GHDCInfo,
  GHDCSearch,
  Origin,
} from "../interfaces/gh-dcinfo";
import { GHServiceService } from "../services/gh-service.service";

declare const require;

//TODO Extract to external file? (or save in common)?
enum ErrorCode {
  EMPTY_FIELD = "requiredErrorMessage",
  WRONG_FORMAT = "patternErrorMessage",
  MAX_LENGTH_EXCEEDED = "maxLengthErrorMessage",
  INVALID_DATE = "invalidDate",
  INVALID_INVOICE_DATE = "invalidInvoiceDate",
  INVALID_TREATMENT_DATE_FROM = "invalidTreatmentDateFrom",
  INVALID_TREATMENT_DATE_TO = "invalidTreatmentDateTo",
  OLDER_INVALID_INVOICE_DATE = "olderInvalidInvoiceDate",
  OLDER_INVALID_TREATMENT_DATE_FROM = "olderInvalidTreatmentDateFrom",
  OLDER_INVALID_TREATMENT_DATE_TO = "olderInvalidTreatmentDateTo",
}

const commonFields: Array<string> = new Array<string>(
  "documentType",
  "countryOfTreatment",
  "invoiceCurrency",
  "insuredPerson",
  "documentQuality"
);

const outpatientFields: Array<string> = new Array<string>(
  "invoiceNumber",
  "invoiceAmount",
  "invoiceDate",
  "treatmentDateFrom",
  "treatmentDateTo",
  "providerName",
  "providerCountry",
  "providerCity",
  "providerPostalCode",
  "providerStreet",
  "providerHouseNo",
  "providerCategory",
  "diagnosis1",
  "diagnosis2",
  "diagnosis3",
  "quantity"
);

const inpatientFields: Array<string> = outpatientFields;

const dentalFields: Array<string> = outpatientFields;

const visionFields: Array<string> = dentalFields;

const pharmacieFields: Array<string> = dentalFields;

const unreadableFields: Array<string> = new Array<string>("documentType");

@Component({
  selector: "app-gh-data-entry-viewer",
  templateUrl: "./gh-data-entry-viewer.component.html",
  styleUrls: ["./gh-data-entry-viewer.component.css"],
})
export class GHDataEntryViewerComponent implements OnInit {
  @Input("startDateTime") startDateTime: Date;
  @Input("productionView") productionView: boolean;
  @Input("isSaved") isSaved: boolean;
  @Input("commonInfo") commonInfo: CommonInfoFolder;
  @Input("fieldsComparationStyle")
  fieldsComparationStyle: FieldComparationStyle = {
    documentType: FieldComparationOption.NA,
    countryOfTreatment: FieldComparationOption.NA,
    invoiceCurrency: FieldComparationOption.NA,
    insuredPerson: FieldComparationOption.NA,
    invoiceNumber: FieldComparationOption.NA,
    invoiceAmount: FieldComparationOption.NA,
    invoiceDate: FieldComparationOption.NA,
    treatmentDateFrom: FieldComparationOption.NA,
    treatmentDateTo: FieldComparationOption.NA,
    providerName: FieldComparationOption.NA,
    providerCity: FieldComparationOption.NA,
    providerStreet: FieldComparationOption.NA,
    providerPostalCode: FieldComparationOption.NA,
    providerHouseNo: FieldComparationOption.NA,
    providerCountry: FieldComparationOption.NA,
    providerCategory: FieldComparationOption.NA,
    diagnosis1: FieldComparationOption.NA,
    diagnosis2: FieldComparationOption.NA,
    diagnosis3: FieldComparationOption.NA,
    quantity: FieldComparationOption.NA,
    documentQuality: FieldComparationOption.NA,
  };

  @ViewChild("deform", { read: ViewContainerRef }) ref: ViewContainerRef;

  @Output("disableSave") disableSave = new EventEmitter<boolean>();

  fieldStyleOptions: FieldComparationOption;

  idForm: string = "test";
  isFormEnabled: boolean = true;

  disableSend: boolean = false;
  disableClose: boolean = false;
  disableCR: boolean = true;
  showSend: boolean = true;
  showClose: boolean = true;
  showCR: boolean = false;

  isFilled: boolean = false;
  claimNumber: string = "";

  //TODO get from TranslateService
  currentLanguage: string = "en";
  alwaysVisibleFields: Array<string> = new Array<string>(
    "documentType",
    "countryOfTreatment",
    "invoiceCurrency",
    "insuredPerson"
  );
  visibleFields: Array<string> = new Array<string>();

  heightWindow: string;
  datePattern = /(([1-2][0-9]|3[0-1]|0[1-9])(0[1-9]|1[0-2])(\d{4}))|(\*)$/;
  numberPattern = /^[0-9]+$/;
  decimalNumberPattern = /(^[0-9]+([.][0-9][0-9]?)?)|(\*)$/;
  quantityPattern = /((^[0-9]+$)|(NA))|(\*)$/;

  documentTypeOptions: Array<string> = new Array<string>();

  providerCategoryOptions: Array<string> = new Array<string>(
    "Diagnostics/Laboratory",
    "Practice",
    "Pharmacy",
    "Clinic",
    "Medical Center",
    "General Hospital",
    "Other non-medical services",
    "N/A"
  );

  documentQualityOptions: Array<string> = new Array<string>(
    "Handwritten",
    "Printed Bad Quality",
    "Printed Good Quality"
  );

  insuredPersonOptions: Array<string> = new Array<string>(
    "UNKNOWN",
    "NOT AVAILABLE"
  );

  ocr: string = null;
  ocrDates: Array<string> = null;
  ocrMath: Array<string> = null;
  simplifiedOcr: string = null;

  countries: any;
  currencies = {
    EUR: "Euros",
    GBP: "Libras",
    SGD: "Singapore dollar",
    AED: "UAE Dirham",
    HKD: "Hong Kong dollar",
    MXN: "Mexican peso",
    CHF: "Swiss Franc",
    BRL: "Brazilian Real",
    THB: "Thai Baht",
    MYR: "Malaysian Ringgit"
  };
  currencyOnCountry = {
    ES: "EUR",
    GB: "GB",
    LU: "EUR",
    SG: "SGD",
    AE: "AED",
    HK: "HKD",
    MX: "MXN",
    CH: "CHF",
    BRL: "BRL",
    THA: "THB",
    MY: "MYR"
  };
  dataEntryForm: FormGroup;

  defaultValues: GHDCInfo = {
    providerId: null,
    documentType: null,
    countryOfTreatment: null,
    invoiceCurrency: null,
    insuredPerson: null,
    invoiceNumber: "00",
    invoiceAmount: null,
    invoiceDate: null,
    treatmentDateFrom: null,
    treatmentDateTo: null,
    providerName: null,
    providerCity: null,
    providerStreet: null,
    providerPostalCode: null,
    providerHouseNo: null,
    providerCountry: null,
    providerCategory: null,
    diagnosis1: "R69",
    diagnosis2: null,
    diagnosis3: null,
    quantity: "1",
    origin: null,
    documentQuality: null,
  };

  origin: Origin = null;
  shouldShowDateError: boolean = false;
  dateErrorMessage: string = "";
  isInvoiceDateValid: boolean = true;
  isTreatmentDateFromValid: boolean = true;
  isTreatmentDateToValid: boolean = true;
  searchModalParentComponentName = "app-gh-data-conflict-viewer";
  skipGoogleSearch: boolean = false;
  isDisplayingCustomerReview = false;

  constructor(
    public dialog: MatDialog,
    protected language: TranslateService,
    protected ghService: GHServiceService,
    protected notification: MatSnackBar,
    protected dataEntryCommonService: DataEntryControllerService,
    protected commonControllerService: CommonControllerService,
    protected authService: AuthService
  ) {
    this.initializeForm();
    i18nIsoCountries.registerLocale(
      require("i18n-iso-countries/langs/" + this.currentLanguage + ".json")
    );
    this.countries = i18nIsoCountries.getNames(this.currentLanguage);
  }

  initializeForm(): void {
    this.dataEntryForm = new FormGroup({
      //VAR OF DATA ENTRY PROCESS 1
      de1: new FormGroup({
        documentType: new FormControl("", [Validators.required]),
        countryOfTreatment: new FormControl("", [Validators.required]),
        invoiceCurrency: new FormControl("", [
          Validators.required,
          Validators.maxLength(100),
        ]),
        insuredPerson: new FormControl("", [
          Validators.required,
          Validators.maxLength(100),
        ]),
        invoiceNumber: new FormControl("", [
          Validators.required,
          Validators.maxLength(100),
        ]),
        invoiceAmount: new FormControl("", [
          Validators.required,
          Validators.maxLength(100),
          Validators.pattern(this.decimalNumberPattern),
        ]),
        invoiceDate: new FormControl("", [
          Validators.required,
          Validators.maxLength(100),
          Validators.pattern(this.datePattern),
        ]),
        treatmentDateFrom: new FormControl("", [
          Validators.required,
          Validators.maxLength(100),
          Validators.pattern(this.datePattern),
        ]),
        treatmentDateTo: new FormControl("", [
          Validators.required,
          Validators.maxLength(100),
          Validators.pattern(this.datePattern),
        ]),
        providerName: new FormControl("", [
          Validators.required,
          Validators.maxLength(100),
        ]),
        providerCity: new FormControl("", [Validators.maxLength(100)]),
        providerStreet: new FormControl("", [Validators.maxLength(100)]),
        providerPostalCode: new FormControl("", [Validators.maxLength(100)]),
        providerHouseNo: new FormControl("", [Validators.maxLength(100)]),
        providerCountry: new FormControl("", [
          Validators.required,
          Validators.maxLength(100),
        ]),
        providerCategory: new FormControl("", [
          Validators.required,
          Validators.maxLength(100),
        ]),
        diagnosis1: new FormControl("", [
          Validators.required,
          Validators.maxLength(100),
        ]),
        diagnosis2: new FormControl("", [Validators.maxLength(100)]),
        diagnosis3: new FormControl("", [Validators.maxLength(100)]),
        quantity: new FormControl("", [
          Validators.required,
          Validators.maxLength(100),
          Validators.pattern(this.quantityPattern),
        ]),
        documentQuality: new FormControl("", [
          Validators.required,
          Validators.maxLength(100),
        ]),
      }),
      cr: new FormGroup({
        remarks: new FormControl("", Validators.maxLength(200)),
      }),
    });
  }

  ngOnInit() {
    var toolbarHeight = (
      document.getElementsByClassName("mat-toolbar-row").item(0) as HTMLElement
    ).offsetHeight;
    this.heightWindow =
      document.documentElement.clientHeight - toolbarHeight + "px";
    document.getElementById("viewportDE").style.height = this.heightWindow;
    this.getOcr();
    this.getDefaultValues();
    this.skipGoogleSearch = this.isQAView();

    if (!this.isDisplayingCustomerReview) this.restrainSearchFields();
  }

  async getDefaultValues(): Promise<void> {
    var defaultValues = await this.getValidOptions();
    var identificationValues = defaultValues["identification"];
    this.defaultValues.documentType =
      defaultValues["folderBookmark"].toUpperCase();
    this.defaultValues.countryOfTreatment =
      identificationValues[3].toUpperCase();
    this.resetToDefaultValues(true);
  }

  async getValidOptions(): Promise<any> {
    var defaultValues = await this.ghService.GetDefaultValues(
      this.commonInfo.folderHash,
      this.commonInfo.tagId
    );
    for (
      var i = 0;
      i < (defaultValues["projectBookmarks"] as string).length;
      i++
    ) {
      this.documentTypeOptions.push(
        defaultValues["projectBookmarks"][i].toUpperCase()
      );
    }
    var insuredPerson: string =
      defaultValues["identification"][1].toUpperCase() +
      " " +
      defaultValues["identification"][2].toUpperCase();
    this.insuredPersonOptions.push(insuredPerson);
    this.defaultValues.insuredPerson = insuredPerson;
    localStorage.setItem("claimNumber", defaultValues["identification"][0]);
    return defaultValues;
  }

  async getOcr() {
    if (!this.ocr) {
      let rawOcr;
      rawOcr = await this.commonControllerService.GetOcr(
        this.commonInfo.folderHash,
        this.commonInfo.tagId
      );
      this.ocr = "";
      for (var value of Object.values(rawOcr)) {
        this.ocr += value;
      }
      this.ocr = this.ocr.toUpperCase();

      this.ocrDates = this.ocr.match(
        /([1-2][0-9]|3[0-1]|0[1-9])[./-:]?(0[1-9]|1[0-2])[./-:]?(\d{4})/g
      );
      this.ocrMath = this.ocr.match(/[0-9]+([.,]\d{0,1}[0-9])?/g);
      this.simplifiedOcr = this.simplifyText(this.ocr);
    }
  }

  simplifyText(text: string): string {
    text = text.split('"').join(" ");
    text = text.split(/[^\w\s]/).join("");
    return text;
  }

  restrainSearchFields() {
    this.disableField("providerStreet");
    this.disableField("providerHouseNo");
    this.disableField("providerPostalCode");
    this.disableField("providerCity");
    this.disableField("providerCategory");
    this.disableField("providerName");
  }

  disableField(fieldId) {
    let field = this.dataEntryForm.get("de1").get(fieldId);
    field.disable();
  }

  getErrorMessageCode(field: AbstractControl): string {
    var message: string = null;

    if (field.hasError("required")) {
      message = ErrorCode.EMPTY_FIELD;
    } else if (field.hasError("pattern")) {
      message = ErrorCode.WRONG_FORMAT;
    } else if (field.hasError("maxlength")) {
      message = ErrorCode.MAX_LENGTH_EXCEEDED;
    }

    return message;
  }

  onDocumentTypeChanged(): void {
    this.resetToDefaultValues();
    this.changeDataEntryVisibility(true);
    this.applyDocumentTypeRestriction();
    this.checkOcrMatch("documentType");
  }

  applyDocumentTypeRestriction(
    documentType: string = this.getDocumentType().value
  ): void {
    this.visibleFields = commonFields;
    switch (documentType) {
      case "OUTPATIENT":
        this.visibleFields = this.visibleFields.concat(outpatientFields);
        break;

      case "INPATIENT":
        this.visibleFields = this.visibleFields.concat(inpatientFields);
        break;

      case "DENTAL":
        this.visibleFields = this.visibleFields.concat(dentalFields);
        break;

      case "VISION":
        this.visibleFields = this.visibleFields.concat(visionFields);
        this.getTreatmentDateTo().disable();
        break;

      case "PHARMACIE":
        this.visibleFields = this.visibleFields.concat(pharmacieFields);
        this.getTreatmentDateFrom().disable();
        this.getTreatmentDateTo().disable();
        break;

      case "NOT AN INVOICE":
      case "UNREADABLE":
        this.visibleFields = unreadableFields;
        break;

      default:
        console.error("Invalid document type selected: " + documentType);
        this.visibleFields = new Array<string>();
        break;
    }
  }

  onInsuredPersonChanged(): void {
    var insuredPerson = this.getInsuredPerson().value;
    if (insuredPerson != "UNKNOWN" && insuredPerson != "NOT AVAILABLE") {
      this.changeDataEntryVisibility(true);
      this.onDocumentTypeChanged();
      this.getInsuredPerson().setValue(insuredPerson);
      this.checkOcrMatch("insuredPerson");
    } else {
      this.changeDataEntryVisibility(false);
      this.getInsuredPerson().setValue(insuredPerson);
      this.getInsuredPerson().enable();
    }
  }

  changeDataEntryVisibility(enabledDataEntry: boolean): void {
    if (enabledDataEntry) {
      this.dataEntryForm.enable();
      this.applyDocumentTypeRestriction();

      if (!this.isDisplayingCustomerReview) this.restrainSearchFields();
    } else {
      this.resetToDefaultValues();
      this.dataEntryForm.disable();
    }
    this.isFormEnabled = enabledDataEntry;
  }

  resetToDefaultValues(resetDocumentType: boolean = false): void {
    if (resetDocumentType) {
      this.getDocumentType().setValue(this.defaultValues.documentType);
      this.onDocumentTypeChanged();
      return; //this method is called again on <this.onDocumentTypeChanged()> and update the rest of fields
    }
    this.getCountryOfTreatment().setValue(
      this.defaultValues.countryOfTreatment
    );
    this.getInvoiceCurrency().setValue(this.defaultValues.invoiceCurrency);
    this.getInsuredPerson().setValue(this.defaultValues.insuredPerson);
    this.getInvoiceNumber().setValue(this.defaultValues.invoiceNumber);
    this.getInvoiceAmount().setValue(this.defaultValues.invoiceAmount);
    this.getInvoiceDate().setValue(this.defaultValues.invoiceDate);
    this.getTreatmentDateFrom().setValue(this.defaultValues.treatmentDateFrom);
    this.getTreatmentDateTo().setValue(this.defaultValues.treatmentDateTo);
    this.getProviderName().setValue(this.defaultValues.providerName);
    this.getProviderCity().setValue(this.defaultValues.providerCity);
    this.getProviderStreet().setValue(this.defaultValues.providerStreet);
    this.getProviderPostalCode().setValue(
      this.defaultValues.providerPostalCode
    );
    this.getProviderHouseNo().setValue(this.defaultValues.providerHouseNo);
    this.getProviderCountry().setValue(this.defaultValues.countryOfTreatment);
    this.getProviderCategory().setValue(this.defaultValues.providerCategory);
    this.getDiagnosis1().setValue(this.defaultValues.diagnosis1);
    this.getDiagnosis2().setValue(this.defaultValues.diagnosis2);
    this.getDiagnosis3().setValue(this.defaultValues.diagnosis3);
    this.getQuantity().setValue(this.defaultValues.quantity);
    this.getDocumentQuality().setValue(this.defaultValues.documentQuality);
    this.onCountryOfTreatmentChanged();

    this.checkOcrOnVisibleFields();
  }

  checkOcrOnVisibleFields(): number {
    console.debug("Checking OCR on ALL visible fields...");
    var totalMatches = 0;
    //We check in all visible fields except the "remarks" and "documentQuality" fields
    this.visibleFields.forEach((fieldKey) => {
      if (fieldKey != "remarks" && fieldKey != "documentQuality") {
        if (this.checkOcrMatch(fieldKey)) {
          totalMatches++;
        }
      }
    });

    return totalMatches;
  }

  checkOcrMatch(fieldKey: string, fieldType: string = null): boolean {
    var field: AbstractControl = this.getField(fieldKey);
    var isMatch: boolean = true;
    if (
      this.ocr &&
      field != null &&
      !field.invalid &&
      field.value != null &&
      field.value != ""
    ) {
      switch (fieldType) {
        case "DATE":
          isMatch = this.checkValueWithOcrCollection(
            field.value,
            this.ocrDates
          );
          isMatch = isMatch
            ? isMatch
            : this.checkValueWithSimplifiedOcr(field.value);
          break;
        case "MATH":
          isMatch = this.checkValueWithOcrCollection(field.value, this.ocrMath);
          isMatch = isMatch
            ? isMatch
            : this.checkValueWithSimplifiedOcr(field.value);
          break;
        default:
          isMatch = this.checkValueWithSimplifiedOcr(field.value);
          break;
      }
      this.processMatchStyle(fieldKey, isMatch);
    } else {
      this.removeOcrStyle(fieldKey);
    }
    return isMatch;
  }

  checkValueWithSimplifiedOcr(value: string): boolean {
    value = this.simplifyText(value.toString().toUpperCase());
    return this.simplifiedOcr.includes(value);
  }

  checkValueWithOcrCollection(
    value: string,
    collection: Array<string>
  ): boolean {
    value = this.simplifyText(value.toUpperCase());
    var isMatch: boolean = false;
    if (collection != null) {
      for (var i = 0; i < collection.length && !isMatch; i++) {
        if (this.simplifyText(collection[i]) == value) {
          isMatch = true;
        }
      }
    }
    return isMatch;
  }

  processMatchStyle(key: string, match: boolean) {
    if (match) {
      this.applyOcrMatchStyle(key);
    } else {
      this.applyOcrNotMatchStyle(key);
    }
  }

  applyOcrMatchStyle(key: string): void {
    key = "de-" + key;
    var element = document.getElementById(key);
    if (element) {
      if (!element.classList.contains("ocrMatch")) {
        element.classList.add("ocrMatch");
      }
      if (element.classList.contains("ocrMismatch")) {
        element.classList.remove("ocrMismatch");
      }
    } else {
      console.error("Cannot find the field element: ", key);
    }
  }

  applyOcrNotMatchStyle(key: string): void {
    key = "de-" + key;
    var element = document.getElementById(key);

    if (element) {
      if (!element.classList.contains("ocrMismatch")) {
        element.classList.add("ocrMismatch");
      }
      if (element.classList.contains("ocrMatch")) {
        element.classList.remove("ocrMatch");
      }
    } else {
      console.error("Cannot find the field element: ", key);
    }
  }

  removeOcrStyle(key: string): void {
    key = "de-" + key;
    var element = document.getElementById(key);

    if (element) {
      if (element.classList.contains("ocrMismatch")) {
        element.classList.remove("ocrMismatch");
      }
      if (element.classList.contains("ocrMatch")) {
        element.classList.remove("ocrMatch");
      }
    } else {
      console.error("Cannot find the field element: ", key);
    }
  }

  onProviderCountryChanged(): void {
    var countryCode: string = this.getProviderCountry().value;
    this.getCountryOfTreatment().setValue(countryCode);
    this.onCountryOfTreatmentChanged();
    this.checkOcrMatch("providerCountry");
  }

  onCountryOfTreatmentChanged(): void {
    var countryCode: string = this.getCountryOfTreatment().value;
    this.changeDataEntryVisibility(
      countryCode == this.defaultValues.countryOfTreatment
    );
    this.getProviderCountry().setValue(countryCode);
    this.updateInvoiceCurrency(countryCode);

    if (countryCode != this.defaultValues.countryOfTreatment) {
      this.getCountryOfTreatment().setValue(countryCode);
      this.getCountryOfTreatment().enable();
    }

    this.checkOcrMatch("countryOfTreatment");
  }

  updateInvoiceCurrency(countryCode: string): void {
    if (this.currencyOnCountry[countryCode]) {
      this.getInvoiceCurrency().setValue(
        this.currencyOnCountry[countryCode].toUpperCase()
      );
      this.checkOcrMatch("invoiceCurrency");
    }
  }

  onInvoiceDateChanged(): void {
    var invoiceDate = this.getInvoiceDate();
    if (!invoiceDate.invalid) {
      if (this.getDocumentType().value == "PHARMACIE") {
        this.getTreatmentDateFrom().setValue(invoiceDate.value);
        this.getTreatmentDateTo().setValue(invoiceDate.value);
        this.onTreatmentDateFromChanged();
        this.onTreatmentDateToChanged();
      }
      this.areValidDates();
      if (this.isInvoiceDateValid) {
        this.checkOcrMatch("invoiceDate", "DATE");
      } else {
        this.removeOcrStyle("invoiceDate");
      }
    } else {
      this.removeOcrStyle("invoiceDate");
    }
  }

  onTreatmentDateFromChanged(): void {
    if (!this.getTreatmentDateFrom().invalid) {
      if (
        this.getDocumentType().value == "DENTAL" ||
        this.getDocumentType().value == "VISION"
      ) {
        this.getTreatmentDateTo().setValue(this.getTreatmentDateFrom().value);
        this.onTreatmentDateToChanged();
      }
      this.areValidDates();
      if (this.isTreatmentDateFromValid) {
        this.checkOcrMatch("treatmentDateFrom", "DATE");
      } else {
        this.removeOcrStyle("treatmentDateFrom");
      }
    } else {
      this.removeOcrStyle("treatmentDateFrom");
    }
  }

  onTreatmentDateToChanged() {
    if (!this.getTreatmentDateTo().invalid) {
      this.areValidDates();
      if (this.isTreatmentDateToValid) {
        this.checkOcrMatch("treatmentDateTo", "DATE");
      } else {
        this.removeOcrStyle("treatmentDateTo");
      }
    } else {
      this.removeOcrStyle("treatmentDateTo");
    }
  }

  getField(fieldKey: string): AbstractControl {
    return this.dataEntryForm.get("de1").get(fieldKey);
  }

  getDocumentType(): AbstractControl {
    return this.dataEntryForm.get("de1").get("documentType");
  }

  getInvoiceCurrency(): AbstractControl {
    return this.dataEntryForm.get("de1").get("invoiceCurrency");
  }

  getCountryOfTreatment(): AbstractControl {
    return this.dataEntryForm.get("de1").get("countryOfTreatment");
  }

  getInsuredPerson(): AbstractControl {
    return this.dataEntryForm.get("de1").get("insuredPerson");
  }

  getInvoiceDate(): AbstractControl {
    return this.dataEntryForm.get("de1").get("invoiceDate");
  }

  getInvoiceAmount(): AbstractControl {
    return this.dataEntryForm.get("de1").get("invoiceAmount");
  }

  getInvoiceNumber(): AbstractControl {
    return this.dataEntryForm.get("de1").get("invoiceNumber");
  }

  getTreatmentDateFrom(): AbstractControl {
    return this.dataEntryForm.get("de1").get("treatmentDateFrom");
  }

  getTreatmentDateTo(): AbstractControl {
    return this.dataEntryForm.get("de1").get("treatmentDateTo");
  }

  getProviderName(): AbstractControl {
    return this.dataEntryForm.get("de1").get("providerName");
  }

  getProviderCity(): AbstractControl {
    return this.dataEntryForm.get("de1").get("providerCity");
  }

  getProviderStreet(): AbstractControl {
    return this.dataEntryForm.get("de1").get("providerStreet");
  }

  getProviderPostalCode(): AbstractControl {
    return this.dataEntryForm.get("de1").get("providerPostalCode");
  }

  getProviderHouseNo(): AbstractControl {
    return this.dataEntryForm.get("de1").get("providerHouseNo");
  }

  getProviderCountry(): AbstractControl {
    return this.dataEntryForm.get("de1").get("providerCountry");
  }

  getProviderCategory(): AbstractControl {
    return this.dataEntryForm.get("de1").get("providerCategory");
  }

  getDiagnosis1(): AbstractControl {
    return this.dataEntryForm.get("de1").get("diagnosis1");
  }

  getDiagnosis2(): AbstractControl {
    return this.dataEntryForm.get("de1").get("diagnosis2");
  }

  getDiagnosis3(): AbstractControl {
    return this.dataEntryForm.get("de1").get("diagnosis3");
  }

  getQuantity(): AbstractControl {
    return this.dataEntryForm.get("de1").get("quantity");
  }

  getDocumentQuality(): AbstractControl {
    return this.dataEntryForm.get("de1").get("documentQuality");
  }

  openDialogSearch(): void {
    const dialogRef = this.dialog.open(GHDataEntrySearchModalComponent, {
      position: { top: "5px" },
      viewContainerRef: this.ref,
      autoFocus: false,
      data: {
        categories: this.providerCategoryOptions,
        country: !this.getCountryOfTreatment().invalid
          ? this.getCountryOfTreatment().value
          : "",
        ocr: this.ocr,
        skipGoogleSearch: this.skipGoogleSearch,
      },
    });

    dialogRef.afterOpened().subscribe((x) => {
      var divOverlay = document
        .getElementsByClassName("cdk-global-overlay-wrapper")
        .item(0) as HTMLElement;
      divOverlay.removeAttribute("class");
      divOverlay.classList.add("classOverlay");

      //remove the current parent.
      var dialog = document
        .getElementsByClassName("cdk-overlay-container")
        .item(0) as HTMLElement;
      dialog.parentElement.removeChild(dialog);

      //Put the child in hte correct site.
      var parentConflict = document.getElementsByTagName(
        this.searchModalParentComponentName
      );
      if (parentConflict.length <= 0) {
        var parent = document
          .getElementsByTagName("app-gh-data-entry-viewer")
          .item(0) as HTMLElement;
        parent.append(dialog);
      } else {
        var parent = parentConflict.item(0) as HTMLElement;
        parent.append(dialog);
      }
    });

    dialogRef.afterClosed().subscribe(
      (result) => {
        if (result) {
          var searchResult: GHDCSearch = result[0];
          var category: string = result[1];
          console.debug("SEARCH: ", searchResult, "CATEGORY: ", category);

          this.getProviderName().setValue(searchResult.provider_name);
          this.getProviderCountry().setValue(searchResult.country);
          this.onProviderCountryChanged();
          this.getProviderStreet().setValue(searchResult.street_name);
          this.getProviderCity().setValue(searchResult.city);
          this.getProviderPostalCode().setValue(searchResult.post_code);
          this.getProviderHouseNo().setValue(searchResult.street_number);
          this.getProviderCategory().setValue(category);

          this.origin = searchResult.origin;
        }
        //We put the notification in the correct site.
        var notification = document
          .getElementsByClassName("cdk-overlay-container")
          .item(0) as HTMLElement;
        notification.parentElement.removeChild(notification);

        var parent = document
          .getElementsByTagName("body")
          .item(0) as HTMLElement;
        parent.append(notification);
      },
      (error) => {
        console.log("The dialog was closed ERROR " + error);
      }
    );
  }

  async close() {
    this.disableClose = true;
    this.disableSend = true;
    var response = await this.commonControllerService.UnlockUser(
      this.commonInfo.folderHash,
      this.commonInfo.tagId
    );
    this.notification
      .open(response, "", {
        duration: 2000,
        panelClass: "centeredNotification",
      })
      .afterDismissed()
      .toPromise()
      .then((response) => {
        self.close();
        this.disableClose = false;
        this.disableSend = false;
      });
  }

  sendToCostumerReview(): void {}

  async onSubmitDE1() {
    console.debug("Trying to submit Data Entry 1");

    if (this.areValidData()) {
      this.sendDataEntry("de1");
    } else {
      this.showErrorNotification();
    }
  }

  showErrorNotification(): void {
    this.disableSend = true;
    this.disableClose = true;
    console.debug("Invalid data");
    this.notification
      .open(
        "There is some wrong/missing information. Please, check fields and make sure that all are correct.",
        "",
        {
          duration: 3000,
          panelClass: "centeredNotification",
        }
      )
      .afterDismissed()
      .toPromise()
      .then((x) => {
        this.disableSend = false;
        this.disableClose = false;
      });
  }

  areValidData(): boolean {
    var areValidData: boolean = true;

    if (this.isFormEnabled) {
      if (!this.getDocumentType().invalid) {
        for (var i = 0; i < this.visibleFields.length; i++) {
          if (
            this.visibleFields[i] != "remarks" &&
            this.visibleFields[i] != "origin"
          ) {
            if (
              this.dataEntryForm.get("de1").get(this.visibleFields[i])?.value ==
              ""
            ) {
              this.dataEntryForm
                .get("de1")
                .get(this.visibleFields[i])
                .setValue(null);
            }
            areValidData =
              areValidData &&
              !this.dataEntryForm.get("de1").get(this.visibleFields[i])
                ?.invalid;
          }
        }

        if (this.visibleFields.indexOf("invoiceDate") != -1) {
          areValidData = areValidData && this.areValidDates();
        }
      } else {
        areValidData = false;
      }
    }

    return areValidData;
  }

  //Get number of fields that have checked the OCR.
  getOCRTotal() {
    var totalVisibleFields = this.visibleFields.length;

    //We don't apply the OCR in remarks field
    if (this.visibleFields.includes("remarks")) {
      totalVisibleFields--;
    }

    //We don't apply the OCR in documentQuality field
    if (this.visibleFields.includes("documentQuality")) {
      totalVisibleFields--;
    }

    return totalVisibleFields;
  }

  //Get number of fields that the OCR not match with the response
  getOCRError() {
    var totalMatches = this.checkOcrOnVisibleFields();
    return this.getOCRTotal() - totalMatches;
  }

  async sendDataEntry(dataEntryIdentifier: string): Promise<void> {
    var dataToSend: GHDCInfo = this.generateDataFromForm(dataEntryIdentifier);
    console.debug("Sending Data Entry...");

    if (dataToSend) {
      console.debug(dataToSend);
      this.disableSend = true;
      this.disableClose = true;
      var sendResponse: string = await this.ghService.SendForm(
        dataToSend,
        this.authService.getUsername(),
        this.commonInfo.folderHash,
        this.commonInfo.project,
        this.startDateTime,
        this.commonInfo.tagId,
        this.commonInfo.status,
        false,
        this.getOCRTotal(),
        this.getOCRError()
      );
      this.notification
        .open(sendResponse, "", {
          duration: 2000,
          panelClass: "centeredNotification",
        })
        .afterDismissed()
        .toPromise()
        .then((x) => {
          if (sendResponse == "The form has been filled susccessfully.") {
            self.close();
          } else {
            this.disableSend = false;
            this.disableClose = false;
          }
        });
      console.debug("Send response: " + sendResponse);
    }
  }

  generateDataFromForm(dataEntryIdentifier: string): GHDCInfo {
    var deFormResult: GHDCInfo;
    var dataEntry = this.dataEntryForm.getRawValue();

    if (dataEntry[dataEntryIdentifier]) {
      deFormResult = dataEntry[dataEntryIdentifier];
    }

    if (deFormResult.quantity == "NA") {
      deFormResult.quantity = "NaN";
    }

    deFormResult.origin = this.origin;

    return deFormResult;
  }

  areValidDates(): boolean {
    this.shouldShowDateError = false;
    const invoiceDate: DateFormat = this.parseToDate(
      this.getInvoiceDate().value
    );
    const treatmentDateTo: DateFormat = this.parseToDate(
      this.getTreatmentDateTo().value
    );
    const treatmentDateFrom: DateFormat = this.parseToDate(
      this.getTreatmentDateFrom().value
    );
    var currentDate: DateFormat = this.getCurrentDate();

    //invoiceDate check
    if (!this.getInvoiceDate().invalid && this.getInvoiceDate().value != "*") {
      if (
        !this.isBeforeOrEqual(invoiceDate, currentDate) ||
        currentDate.year - 10 > invoiceDate.year
      ) {
        this.dateErrorMessage =
          currentDate.year - 10 > invoiceDate.year
            ? ErrorCode.OLDER_INVALID_INVOICE_DATE
            : ErrorCode.INVALID_INVOICE_DATE;
        this.shouldShowDateError = true;
        this.isInvoiceDateValid = false;
      } else {
        this.isInvoiceDateValid = true;
      }
    }

    //treatmentDateTo check
    if (
      !this.shouldShowDateError &&
      !this.getTreatmentDateTo().invalid &&
      this.getTreatmentDateTo().value != "*"
    ) {
      if (
        !this.isBeforeOrEqual(treatmentDateTo, currentDate) ||
        currentDate.year - 10 > treatmentDateTo.year
      ) {
        this.dateErrorMessage =
          currentDate.year - 10 > treatmentDateTo.year
            ? ErrorCode.OLDER_INVALID_TREATMENT_DATE_TO
            : ErrorCode.INVALID_TREATMENT_DATE_TO;
        this.shouldShowDateError = true;
        this.isTreatmentDateToValid = false;
      } else {
        this.isTreatmentDateToValid = true;
      }
    }

    //treatmentDateFrom check
    if (
      !(this.shouldShowDateError || this.getTreatmentDateFrom().invalid) &&
      this.getTreatmentDateFrom().value != "*"
    ) {
      if (
        (!this.getTreatmentDateTo().invalid &&
          !this.isBeforeOrEqual(treatmentDateFrom, treatmentDateTo)) ||
        !this.isBeforeOrEqual(treatmentDateFrom, currentDate) ||
        currentDate.year - 10 > treatmentDateFrom.year
      ) {
        this.dateErrorMessage = ErrorCode.INVALID_TREATMENT_DATE_FROM;
        this.dateErrorMessage =
          currentDate.year - 10 > treatmentDateFrom.year
            ? ErrorCode.OLDER_INVALID_TREATMENT_DATE_FROM
            : this.dateErrorMessage;
        this.shouldShowDateError = true;
        this.isTreatmentDateFromValid = false;
      } else {
        this.isTreatmentDateFromValid = true;
      }
    }

    return !this.shouldShowDateError;
  }

  getCurrentDate(): DateFormat {
    var tmpDate: Date = new Date();
    var day: string =
      tmpDate.getDate() > 9
        ? tmpDate.getDate().toString()
        : "0" + tmpDate.getDate().toString();
    var month: string =
      tmpDate.getMonth() + 1 > 9
        ? (tmpDate.getMonth() + 1).toString()
        : "0" + (tmpDate.getMonth() + 1).toString();
    var year: string = tmpDate.getFullYear().toString();
    var currentDate: DateFormat = {
      day: Number.parseInt(day),
      month: Number.parseInt(month),
      year: Number.parseInt(year),
    };
    return currentDate;
  }

  isBeforeOrEqual(date1: DateFormat, date2: DateFormat): boolean {
    var isBefore: boolean = false;

    if (date1 == null || date2 == null) {
      isBefore = true;
    } else if (date1.year < date2.year) {
      isBefore = true;
    } else if (date1.year == date2.year) {
      if (date1.month < date2.month) {
        isBefore = true;
      } else if (date1.month == date2.month) {
        isBefore = date1.day <= date2.day;
      }
    }
    return isBefore;
  }

  parseToDate(date: string): DateFormat {
    var dateFormatted: DateFormat = null;

    if (date != null && date != "" && date.length == 8) {
      var year: string = date.substring(4, 8);
      var month: string = date.substring(2, 4);
      var day: string = date.substring(0, 2);
      dateFormatted = {
        day: Number.parseInt(day),
        month: Number.parseInt(month),
        year: Number.parseInt(year),
      };
    }

    return dateFormatted;
  }

  /* TODO DELETE WHEN LIVE */
  fixCurrencyReceived(): void {
    if (this.defaultValues.invoiceCurrency != null) {
      if (this.defaultValues.invoiceCurrency.toUpperCase().includes("EURO")) {
        this.defaultValues.invoiceCurrency = "EUR";
      } else if (
        this.defaultValues.invoiceCurrency.toUpperCase().includes("LIBRA") ||
        this.defaultValues.invoiceCurrency.toUpperCase() == "GB"
      ) {
        this.defaultValues.invoiceCurrency = "GBP";
      }
    }
  }

  fixDatesReceived(): void {
    var invoiceDate: string = this.defaultValues.invoiceDate;
    var treatmentDateFrom: string = this.defaultValues.treatmentDateFrom;
    var treatmentDateTo: string = this.defaultValues.treatmentDateTo;

    if (invoiceDate != null && invoiceDate.length > 8) {
      this.defaultValues.invoiceDate =
        invoiceDate.substring(0, 2) +
        invoiceDate.substring(3, 5) +
        invoiceDate.substring(6, 10);
    }
    if (treatmentDateFrom != null && treatmentDateFrom.length > 8) {
      this.defaultValues.treatmentDateFrom =
        treatmentDateFrom.substring(0, 2) +
        treatmentDateFrom.substring(3, 5) +
        treatmentDateFrom.substring(6, 10);
    }
    if (treatmentDateTo != null && treatmentDateTo.length > 8) {
      this.defaultValues.treatmentDateTo =
        treatmentDateTo.substring(0, 2) +
        treatmentDateTo.substring(3, 5) +
        treatmentDateTo.substring(6, 10);
    }
  }
  /* END DELETE*/

  isQAView(): boolean {
    var isQA = this.commonInfo.isQA;
    if (isQA) {
      return isQA.toLowerCase() == "true";
    }
    return false;
  }
}
