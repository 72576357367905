import { Component } from "@angular/core";
import { AbstractControl } from "@angular/forms";
import { GHDataEntryViewerComponent } from "../gh-data-entry-viewer/gh-data-entry-viewer.component";
import { GHDCInfo } from "../interfaces/gh-dcinfo";

@Component({
  selector: "app-gh-data-conflict-viewer",
  templateUrl: "../gh-data-entry-viewer/gh-data-entry-viewer.component.html",
  styleUrls: ["../gh-data-entry-viewer/gh-data-entry-viewer.component.css"],
})
export class GHDataConflictViewerComponent extends GHDataEntryViewerComponent {
  private isFirstConfigDone: boolean = false;
  private defaultCountry: string;

  async getDefaultValues(): Promise<void> {
    var firstDefaultValues = await this.getValidOptions();
    this.defaultCountry = firstDefaultValues["identification"][3].toUpperCase();
    var dataEntryValues = await this.ghService.ObtainDEModel(
      this.commonInfo.folderHash,
      this.commonInfo.project,
      this.commonInfo.tagId,
      false
    );

    this.defaultValues = dataEntryValues[0];

    Object.keys(this.defaultValues).forEach((field) => {
      if (field != "origin" && this.defaultValues[field] != null) {
        this.defaultValues[field] = this.defaultValues[field].toString();
      }
    });

    if (this.defaultValues.quantity == "NaN") {
      this.defaultValues.quantity = "NA";
    }
    var dataEntryCountry = this.defaultValues.countryOfTreatment;
    this.defaultValues.countryOfTreatment =
      firstDefaultValues["identification"][3].toUpperCase();
    /* TODO DELETE WHEN LIVE */
    this.fixCurrencyReceived();
    this.fixDatesReceived();
    /* END DELETE*/

    this.resetToDefaultValues(true);
    this.getInvoiceCurrency().setValue(this.defaultValues.invoiceCurrency);

    this.onInsuredPersonChanged();
    this.getCountryOfTreatment().setValue(dataEntryCountry);
    this.onCountryOfTreatmentChanged();
    this.isFirstConfigDone = true;

    this.showCR = true;
    this.disableCR = false;
  }

  async onSubmitDE1(): Promise<void> {
    if (this.areValidData()) {
      this.sendDataConflict("de1", this.isCustomerReviewNeeded());
    } else {
      this.showErrorNotification();
    }
  }

  sendToCostumerReview() {
    this.fillMandatoryFieldsToCR();

    this.areValidData()
      ? this.sendDataConflict("de1", true)
      : this.showErrorNotification();
  }

  fillMandatoryFieldsToCR() {
    for (var i = 0; i < this.visibleFields.length; i++) {
      if (this.visibleFields[i] != "remarks") {
        let field = this.dataEntryForm.get("de1").get(this.visibleFields[i]);

        if (
          field.value == "" &&
          field.validator({} as AbstractControl).required
        ) {
          field.clearValidators();
          this.dataEntryForm.updateValueAndValidity();
          field.setValue("*");
        }
      }
    }
  }

  isCustomerReviewNeeded(): boolean {
    var isCustomerReviewNeeded: boolean = false;
    if (this.isFormEnabled) {
      if (!this.getDocumentType().invalid) {
        for (
          var i = 0;
          i < this.visibleFields.length && !isCustomerReviewNeeded;
          i++
        ) {
          var field: AbstractControl = this.dataEntryForm
            .get("de1")
            .get(this.visibleFields[i]);
          var validator = field.validator({} as AbstractControl);
          var isRequired: boolean = validator && validator.required;
          isCustomerReviewNeeded = isRequired && field.value == "*";
        }
      } else {
        isCustomerReviewNeeded = false;
      }
    }
    return isCustomerReviewNeeded;
  }

  async sendDataConflict(
    dataEntryIdentifier: string,
    customerReviewNeeded: boolean
  ): Promise<void> {
    var dataToSend: GHDCInfo = this.generateDataFromForm(dataEntryIdentifier);
    if (dataToSend) {
      console.debug(dataToSend);
      this.disableSend = true;
      this.disableClose = true;
      var sendResponse: string = await this.ghService.SendForm(
        dataToSend,
        this.authService.getUsername(),
        this.commonInfo.folderHash,
        this.commonInfo.project,
        this.startDateTime,
        this.commonInfo.tagId,
        this.commonInfo.status,
        customerReviewNeeded,
        this.getOCRTotal(),
        this.getOCRError()
      );
      this.notification
        .open(sendResponse, "", {
          duration: 2000,
          panelClass: "centeredNotification",
        })
        .afterDismissed()
        .toPromise()
        .then((x) => {
          if (sendResponse == "The form has been filled susccessfully.") {
            self.close();
          } else {
            this.disableSend = false;
            this.disableClose = false;
          }
        });
      console.debug("Send response: " + sendResponse);
    }
  }
}
