import { Component } from "@angular/core";
import { GHDataEntryViewerComponent } from "../gh-data-entry-viewer/gh-data-entry-viewer.component";
import { AbstractControl } from "@angular/forms";
import { GHDCInfo } from "../interfaces/gh-dcinfo";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "src/app/auth/services/auth.service";
import { CommonControllerService } from "src/app/services/common-controller.service";
import { DataEntryControllerService } from "src/app/services/data-entry-controller.service";
import { GHServiceService } from "../services/gh-service.service";

@Component({
  selector: "app-gh-customer-review-viewer",
  templateUrl: "../gh-data-entry-viewer/gh-data-entry-viewer.component.html",
  styleUrls: ["../gh-data-entry-viewer/gh-data-entry-viewer.component.css"],
})
export class GhCustomerReviewViewerComponent extends GHDataEntryViewerComponent {
  private isFirstConfigDone: boolean = false;

  constructor(
    public dialog: MatDialog,
    protected language: TranslateService,
    protected ghService: GHServiceService,
    protected notification: MatSnackBar,
    protected dataEntryCommonService: DataEntryControllerService,
    protected commonControllerService: CommonControllerService,
    protected authService: AuthService
  ) {
    super(
      dialog,
      language,
      ghService,
      notification,
      dataEntryCommonService,
      commonControllerService,
      authService
    );

    this.isDisplayingCustomerReview = true;
  }

  initializeForm(): void {
    this.datePattern = /(([1-2][0-9]|3[0-1]|0[1-9])(0[1-9]|1[0-2])(\d{4}))$/;
    this.numberPattern = /^[0-9]+$/;
    this.decimalNumberPattern = /(^[0-9]+([.][0-9][0-9]?)?)$/;
    this.quantityPattern = /((^[0-9]+$)|(NA))$/;
    this.searchModalParentComponentName = "app-gh-customer-review-viewer";
    this.skipGoogleSearch = true;
    super.initializeForm();
  }

  sendToCostumerReview(): void {
    throw new Error("Method not implemented.");
  }

  async getDefaultValues(): Promise<void> {
    await this.getValidOptions();
    var defaultValues = await this.ghService.ObtainDEModel(
      this.commonInfo.folderHash,
      this.commonInfo.project,
      this.commonInfo.tagId,
      false
    );

    this.defaultValues = defaultValues[0];

    //We get the fields that we need to show in the customer review
    var customerReviewFields = this.getNewVisibleFields();

    //We change the value '*' for ''.
    Object.keys(this.defaultValues).forEach((field) => {
      if (field != "origin" && this.defaultValues[field] != null) {
        this.defaultValues[field] = this.defaultValues[field].toString();
        //we check if we need to change the value
        if (this.defaultValues[field] == "*") {
          this.defaultValues[field] = "";
        }
      }
    });
    if (this.defaultValues.quantity == "NaN") {
      this.defaultValues.quantity = "NA";
    }

    /* TODO DELETE WHEN LIVE */
    this.fixCurrencyReceived();
    this.fixDatesReceived();
    /* END DELETE*/

    this.resetToDefaultValues(true);
    this.getInvoiceCurrency().setValue(this.defaultValues.invoiceCurrency);
    //We only see the fields that has a * in the value.
    this.visibleFields = customerReviewFields;

    this.isFirstConfigDone = true;
  }

  applyDocumentTypeRestriction() {}
  async onSubmitDE1() {
    console.debug("Trying to submit Customer Review");

    if (this.areValidData()) {
      this.sendCustomerReview("de1", this.getRemarks().value);
    } else {
      this.showErrorNotification();
    }
  }

  getRemarks(): AbstractControl {
    return this.dataEntryForm.get("cr").get("remarks");
  }

  async sendCustomerReview(
    dataEntryIdentifier: string,
    remarks: string
  ): Promise<void> {
    var dataToSend: GHDCInfo = this.generateDataFromForm(dataEntryIdentifier);
    console.debug("Sending Customer Review...");

    if (dataToSend) {
      console.log(dataToSend);
      this.disableSend = true;
      this.disableClose = true;
      var sendResponse: string = await this.ghService.SendForm(
        dataToSend,
        this.authService.getUsername(),
        this.commonInfo.folderHash,
        this.commonInfo.project,
        this.startDateTime,
        this.commonInfo.tagId,
        this.commonInfo.status,
        false,
        0,
        0,
        remarks
      );
      this.notification
        .open(sendResponse, "", {
          duration: 2000,
          panelClass: "centeredNotification",
        })
        .afterDismissed()
        .toPromise()
        .then((x) => {
          if (sendResponse == "The form has been filled susccessfully.") {
            self.close();
          } else {
            this.disableSend = false;
            this.disableClose = false;
          }
        });
      console.debug("Send response: " + sendResponse);
    }
  }

  getNewVisibleFields(): Array<string> {
    var customerReviewFields: Array<string> = new Array<string>("remarks");
    var insertedProviderFields: boolean = false;
    Object.keys(this.defaultValues).forEach((field) => {
      if (field != "origin") {
        if (field.includes("provider")) {
          if (!insertedProviderFields) {
            customerReviewFields = customerReviewFields.concat(
              this.getProviderFields()
            );
            insertedProviderFields = true;
          }
        } else {
          customerReviewFields.push(field);
        }
      }
    });
    console.log(customerReviewFields);
    return customerReviewFields;
  }

  getProviderFields(): Array<string> {
    var providerFields: Array<string> = new Array<string>(
      "providerName",
      "providerCountry",
      "providerCity",
      "providerPostalCode",
      "providerStreet",
      "providerHouseNo",
      "providerCategory"
    );
    return providerFields;
  }
}
