export interface GHDCInfo {
	providerId: string,
	documentType: string,
	countryOfTreatment: string,
	invoiceCurrency: string,
	insuredPerson: string,
	invoiceNumber: string,
	invoiceAmount: string,
	invoiceDate: string,
	treatmentDateFrom: string,
	treatmentDateTo: string,
	providerName: string,
	providerCity: string,
	providerStreet: string,
	providerPostalCode: string,
	providerHouseNo: string,
	providerCountry: string,
	providerCategory: string,
	diagnosis1: string,
	diagnosis2: string,
	diagnosis3: string,
	quantity: string,
	origin: Origin,
	documentQuality: string
}

export interface GHDCSearch {
	provider_id: string,
	man_id: string,
	provider_type: string,
	provider_name: string,
	country: string,
	country_code: string,
	city: string,
	post_code: string,
	street_name: string,
	street_number: string,
	origin: Origin
}

export enum Origin {
	COSTUMER = 0,
	GOOGLE = 1,
	BPO = 2,
}

export enum FieldComparationOption {
	NA = 0,
	EQUAL = 1,
	DIFFERENT = 2,
}

export interface FieldComparationStyle {
	documentType: FieldComparationOption,
	countryOfTreatment: FieldComparationOption,
	invoiceCurrency: FieldComparationOption,
	insuredPerson: FieldComparationOption,
	invoiceNumber: FieldComparationOption,
	invoiceAmount: FieldComparationOption,
	invoiceDate: FieldComparationOption,
	treatmentDateFrom: FieldComparationOption,
	treatmentDateTo: FieldComparationOption,
	providerName: FieldComparationOption,
	providerCity: FieldComparationOption,
	providerStreet: FieldComparationOption,
	providerPostalCode: FieldComparationOption,
	providerHouseNo: FieldComparationOption,
	providerCountry: FieldComparationOption,
	providerCategory: FieldComparationOption,
	diagnosis1: FieldComparationOption,
	diagnosis2: FieldComparationOption,
	diagnosis3: FieldComparationOption,
	quantity: FieldComparationOption,
	documentQuality: FieldComparationOption
}

export interface DateFormat {
	day: number,
	month: number,
	year: number,
}