<!-- This allow the 'npm run extract' detect this translation -->
<span style="display: none;">
  {{ 'country' | translate }}
  {{ 'address' | translate }}
  {{ 'postalCode' | translate }}
</span>
<!-- ------------------------------------------------------- -->
<div id="first-modal" *ngIf="localSearch">
  <h1 mat-dialog-title> Searching provider info </h1>
  <div mat-dialog-content id="content">

    <form id="form" [formGroup]="providerSearch" (ngSubmit)="onSubmit(providerSearch.value)" autocomplete="off">
      <mat-form-field appearance="legacy">
        <mat-label>{{'search' | translate}}</mat-label>
        <input type="text" matInput formControlName="providerSearch" >
      </mat-form-field>

      <button mat-raised-button id="search-button" type="submit"> Search </button>
    </form>

    <mat-divider></mat-divider>

    <div id="table">
      <article id="title">
        <h3> Results </h3>
      </article>

      <div id="tableResult">
        <div id="new-address-button-div">
          <button mat-raised-button *ngIf="showNewAddressButton" (click)="onClickNewAddress()">New address</button>
        </div>

        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" *ngIf="showResult">
          
          <ng-container matColumnDef="providerName">
            <th mat-header-cell *matHeaderCellDef> {{'providerName' | translate}} </th>
            <td mat-cell (dblclick)="doubleClick(element)" *matCellDef="let element"> {{element.provider_name}} </td>
          </ng-container>

          <ng-container matColumnDef="providerAddress">
            <th mat-header-cell *matHeaderCellDef> {{'providerAddress' | translate}} </th>
            <td mat-cell (dblclick)="doubleClick(element)" *matCellDef="let element"> {{element.street_name + ' ' + element.street_number +  ' ' + element.post_code + ' ' + element.city}} </td>
          </ng-container>
          
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
    </div>
  </div>

</div>

<h1 mat-dialog-title *ngIf="!localSearch"> Search by provider name </h1>

<form id="searchPlace" [formGroup]="search" (ngSubmit)="null" autocomplete="off" [style.visibility]="!skipGoogleSearch && !localSearch ? 'visible' : 'hidden'">
    <mat-form-field appearance="legacy" class="searchPlace">
      <mat-label>{{'search' | translate}}</mat-label>
      <input matInput (keydown.enter)="null" type="text" matInput formControlName="searchTerm" #searchTerm autocorrect="off" autocapitalize="off" spellcheck="off" >
    </mat-form-field>
</form>

<div id="second-modal" *ngIf="!localSearch">
    
      <div mat-dialog-content id="content">

          <form id="info" [formGroup]="addressSearch" (ngSubmit)="save()" autocomplete="off">
    
            <mat-form-field id='sm-providerName' appearance="legacy" >
              <mat-label>{{'providerName' | translate}}</mat-label>
              <input  matInput type="text" matInput formControlName="providerName" (change)="noticeOcrMatch('providerName')" #providerNameSearch autocorrect="off" autocapitalize="off" spellcheck="off" >
            </mat-form-field>
    
            <mat-form-field id='sm-country' appearance="legacy" >
              <mat-label>{{'country' | translate}}</mat-label>
              <input  matInput type="text" matInput formControlName="country" (change)="noticeOcrMatch('country')" #countrySearch autocorrect="off" autocapitalize="off" spellcheck="off" >
            </mat-form-field>
      
            <mat-form-field id='sm-postalCode' appearance="legacy">
              <mat-label>{{'postalCode' | translate}}</mat-label>
              <input  matInput type="text" matInput formControlName="postalCode" (change)="noticeOcrMatch('postalCode')" #postalCodeSearch autocorrect="off" autocapitalize="off" spellcheck="off" >
            </mat-form-field>
    
            <mat-form-field id='sm-city' appearance="legacy">
              <mat-label>{{'city' | translate}}</mat-label>
              <input  matInput type="text" matInput formControlName="city" (change)="noticeOcrMatch('city')" #citySearch autocorrect="off" autocapitalize="off" spellcheck="off" >
            </mat-form-field>
    
            <mat-form-field id='sm-street' appearance="legacy">
              <mat-label>{{'street' | translate}}</mat-label>
              <input matInput type="text" matInput formControlName="street" (change)="noticeOcrMatch('street')" #streetSearch autocorrect="off" autocapitalize="off" spellcheck="off" >
            </mat-form-field>
      
            <mat-form-field id='sm-houseNo' appearance="legacy">
              <mat-label>{{'houseNumber' | translate}}</mat-label>
              <input matInput type="text" matInput formControlName="houseNo" (change)="noticeOcrMatch('houseNo')" #houseNoSearch autocorrect="off" autocapitalize="off" spellcheck="off" >
            </mat-form-field>
    
            <mat-form-field id="sm-providerCategory" appearance="legacy">
              <mat-label>{{'providerCategory' | translate}}</mat-label>
              <mat-select formControlName="providerCategory" (selectionChange)="noticeOcrMatch('providerCategory')">
                  <mat-option *ngFor="let option of providerCategoryOptions | keyvalue" value="{{option.value  | uppercase}}"> {{ option.value }}
                  </mat-option>
              </mat-select>
            </mat-form-field>
            
            <button type="button" mat-raised-button id="manualInput" (click)="toggleManualInput()" *ngIf="showManualInputButton"> Manual input </button>
            <button type="submit" mat-raised-button id="saveForm" type="submit"> Save </button>
    
          </form>
    
          <mat-divider></mat-divider>
    
          <button type="submit" mat-raised-button id="displayOcr" type="button" (click)="displayOcr()"> View OCR </button>
          <div id="ocrWindow" [style.display]="ocrVisible ? 'block' : 'none'"></div>
    
    
        </div>
</div>

  