import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { StartComponent } from "./start/start.component";
import { NotFoundPageComponent } from "./not-found-page/not-found-page.component";

const routes: Routes = [
  {
    path: "DataEntry/:id/:id",
    component: StartComponent,
  },
  {
    path: "DataConflict/:id/:id",
    component: StartComponent,
  },
  {
    path: "CustomerReview/:id/:id",
    component: StartComponent,
  },
  {
    path: "**",
    component: NotFoundPageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
