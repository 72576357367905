import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { GHDCInfo } from '../interfaces/gh-dcinfo';
import { FileServiceService } from '../../services/file-service.service';

@Injectable({
  providedIn: 'root'
})
export class GHServiceService {

  apiUrl: string = environment.backendURL;

  constructor(private http: HttpClient, private fileService: FileServiceService) { }

  async GetDefaultValues(folderHash: string, tagId: string) {
    const url = this.apiUrl + '/DataEntryApi/GetGHInformation?folderhash=' + folderHash + '&tagId=' + tagId;
    return await this.http.get(url).toPromise().then(data => {
      return data;
    })
    .catch(error => {
      console.log(error);
      return "A problem has occurred.";
    });
  }

  async SearchProviderData(providerName: string, countryCode: string, maxResultCount: number = null): Promise<any> {
    var url = this.apiUrl + '/DataEntryApi/SearchProvider?providerName=' + providerName + '&country_code=' + countryCode;
    if (maxResultCount) {
      url += '&resultNo=' + maxResultCount.toString();
    }

    return await this.http.get(url).toPromise().then(data => {
      return data;
    })
    .catch(error => {
      console.log(error);
      return "A problem has occurred.";
    });
  }

  async ObtainDEModel(folderHash: string, project: string, tagId: string, isProductionView: boolean) {
    const url = this.apiUrl + '/DataEntryApi/ObtainDEInfo?folderHash=' + folderHash + '&project=' + project + '&isProductionView=' + isProductionView + '&tagId=' + tagId;
    var ghModel: Array<GHDCInfo> = new Array<GHDCInfo>();

    var result = await this.http.get<GHDCInfo>(url).toPromise().then((response) => {
      if (response["dataEntry1"] && response["dataEntry1"] != null) {
        ghModel.push(JSON.parse(response["dataEntry1"]))
      }
      
      if (response["dataEntry2"] && response["dataEntry2"] != null) {
        ghModel.push(JSON.parse(response["dataEntry2"]))
      }

      if (response["dataEntry3"] && response["dataEntry3"] != null) {
        ghModel.push(JSON.parse(response["dataEntry3"]));
      }

      console.debug("Complete response: ", response);
      return ghModel;
    })
    .catch((error) => {
      console.log(error);
      return ghModel;
    });

    return result;
  }

  async SendForm(dataEntryForm, username, folderHash, project, startDateTime, tagId, status, customerReviewNeeded: boolean = false, OCRTotal:number =0, OCRError:number = 0, remarks: string = null) {
    const url = this.apiUrl + '/DataEntryApi';
    var params;
    if (remarks) { //We have done the customerReview
      params = {
        "dataEntry": dataEntryForm,
        "username": username,
        "project": project,
        "folderHash": folderHash,
        "startDateTime": startDateTime,
        "updatedImages": this.fileService.listImages,
        "tagId": tagId,
        "customerReviewNeeded": customerReviewNeeded,
        "remarks": remarks,
        "status": status
      };
    }
    else { //We need to send if we go to customer review or not
      params = {
        "dataEntry": dataEntryForm,
        "username": username,
        "project": project,
        "folderHash": folderHash,
        "startDateTime": startDateTime,
        "updatedImages": this.fileService.listImages,
        "tagId": tagId,
        "customerReviewNeeded": customerReviewNeeded,
        "OCRTotal": OCRTotal,
        "OCRError": OCRError,
        "status": status
      };
    }
    
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return await this.http.post(url, params, { headers }).toPromise()
    .then(data => {
      console.log("POST Request is successful ", data);
      return "The form has been filled susccessfully.";
    })
    .catch(error => {
      console.log(error);
      return "A problem has occurred.";
    })
  }
  
}
