<cdk-virtual-scroll-viewport itemSize="50" id="viewport">
  <article id="title">
    <h3><small> {{boxId}} \ {{folderId}} <div id="claimNumberDiv" *ngIf="localStorage.getItem('claimNumber')"> - {{localStorage.getItem('claimNumber')}}</div> </small></h3>
    <p> Total images: {{nImages}} </p>
  </article>

  <div id="imgs">
    <!--We make a loop with all the images-->
    <div *ngFor="let img of images">
      <div id="menu">
        <mat-toolbar>
          <mat-toolbar-row>
            <span id="title">{{img.fileName}}</span>
            <span class="spacer"></span>
            <div *ngIf="!img.deleted">
              <button mat-stroked-button (click)="rotateImage(img.hash, -1)"> Rotate left </button>
              <button mat-stroked-button (click)="rotateImage(img.hash, 1)"> Rotate right </button>
              <button mat-stroked-button (click)="changeColor(img.hash)" *ngIf="projectAllowChangeColorButton"> Change color </button>
              <button mat-stroked-button (click)="zoomReset(img.hash, false)"> ZoomReset </button>
              <button mat-stroked-button (click)="zoomOut(img.hash, false)"> ZoomOut </button>
              <button mat-stroked-button (click)="zoomIn(img.hash, false)"> ZoomIn </button>
              <button mat-stroked-button (click)="changeDeleteFlag(img.hash, true)" *ngIf="projectAllowDeleteButton && showDeleteButton"> Delete </button>
            </div>
            <div *ngIf="img.deleted">
              <button mat-stroked-button (click)="changeDeleteFlag(img.hash, false)"> Restore </button>
            </div>
          </mat-toolbar-row>
        </mat-toolbar>
      </div>
      <div id="div_image">
        <img src="{{img.image}}" id="{{img.hash}}" class="child" (load)="initializeZoom(img.hash, false)"/>
        <div *ngIf="img.deleted" id="deleteImage" class="deleteImage">DELETED</div>
      </div>
    </div>
  </div>

  <!-- IMAGES FROM THE PARENT -->
  <div *ngIf="showParentImages">
    <mat-divider></mat-divider>
    <article id="title">
      <h3><small> {{boxId}} Parent images: {{parentFolderId}} </small></h3>
      <p> Total images: {{nParentImages}} </p>
    </article>

    <div id="imgs">
      <!--We make a loop with all the images-->
      <div *ngFor="let img of parentImages">
        <div id="menu">
          <mat-toolbar>
            <mat-toolbar-row>
              <span id="title">{{img.fileName}}</span>
              <span class="spacer"></span>
              <button mat-stroked-button (click)="rotateImage(img.hash, -1)"> Rotate left </button>
              <button mat-stroked-button (click)="rotateImage(img.hash, 1)"> Rotate right </button>
              <button mat-stroked-button (click)="changeColor(img.hash)"> Change color </button>
              <button mat-stroked-button (click)="zoomReset(img.hash, true)"> ZoomReset </button>
              <button mat-stroked-button (click)="zoomOut(img.hash, true)"> ZoomOut </button>
              <button mat-stroked-button (click)="zoomIn(img.hash, true)"> ZoomIn </button>
            </mat-toolbar-row>
          </mat-toolbar>
        </div>
        <div id="div_image">
          <img src="{{img.image}}" id="{{img.hash}}" class="parent" (load)="initializeZoom(img.hash, true)" />
        </div>
      </div>
    </div>
  </div>

</cdk-virtual-scroll-viewport>
  

