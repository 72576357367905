<mat-toolbar>
    <mat-toolbar-row>
      <span>Data Entry List</span>
      <!--<span>Identification</span>-->
      <span class="example-spacer"></span>
      <button type="button" mat-button (click)="useLanguage('nl')" *ngIf="showTraduction" id="buttonTrans"> NL </button>
      <button type="button" mat-button (click)="useLanguage('fr')" *ngIf="showTraduction" id="buttonTrans"> FR </button>
      <span id="username"> {{ username }}</span>
  
      <button mat-button [matMenuTriggerFor]="menu" id="buttonMenu">
        <mat-icon aria-hidden="false" aria-label="Example home icon">menu</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item type="button" (click)="logout()"> Logout </button>
      </mat-menu>
  
    </mat-toolbar-row>
  </mat-toolbar>
  
  <as-split direction="horizontal" style="height:100%" *ngIf="isloaded">
  
    <!-- DATA ENTRY -->
    <as-split-area size="70">
      <app-image-viewer [commonInfo]="commonInfo"></app-image-viewer>
    </as-split-area>
    <as-split-area size="30" id="formView" *ngIf="shouldDisplayDE">
      <cdk-virtual-scroll-viewport itemSize="50" id="viewportDE"><app-gh-data-entry-viewer [startDateTime]="startDateTime" [commonInfo]="commonInfo"></app-gh-data-entry-viewer></cdk-virtual-scroll-viewport>
    </as-split-area>
  
    <!-- DATA CONFLICT -->
    <!-- <as-split-area size="70">
      <app-image-viewer [commonInfo]="commonInfo"></app-image-viewer>
    </as-split-area> -->
    <as-split-area size="30" id="formView" *ngIf="shouldDisplayDC">
      <cdk-virtual-scroll-viewport itemSize="50" id="viewportDE">
        <app-gh-data-conflict-viewer [startDateTime]="startDateTime" [commonInfo]="commonInfo"></app-gh-data-conflict-viewer>
      </cdk-virtual-scroll-viewport>
    </as-split-area>
  
    <!-- CUSTOMER REVIEW -->
    <!-- <as-split-area size="70">
      <app-image-viewer [commonInfo]="commonInfo"></app-image-viewer>
    </as-split-area> -->
    <as-split-area size="30" id="formView" *ngIf="shouldDisplayCR">
      <cdk-virtual-scroll-viewport itemSize="50" id="viewportDE">
        <app-gh-customer-review-viewer [startDateTime]="startDateTime" [commonInfo]="commonInfo"></app-gh-customer-review-viewer>
      </cdk-virtual-scroll-viewport>
    </as-split-area>
  
    <!-- PRODUCTION VIEW -->
    <!-- <as-split-area size="60">
      <app-image-viewer [commonInfo]="commonInfo"></app-image-viewer>
    </as-split-area>
    <as-split-area size="40" id="formView">
        <app-gh-production-view [startDateTime]="startDateTime" [commonInfo]="commonInfo"></app-gh-production-view>
    </as-split-area> -->
  
    <!-- <as-split-area size="40">
      <cdk-virtual-scroll-viewport itemSize="50" id="viewportDE"><app-certimed-data-conflict-viewer [startDateTime]="startDateTime"></app-certimed-data-conflict-viewer></cdk-virtual-scroll-viewport>
    </as-split-area> -->
  
    <!--<as-split-area size="40">
      <app-certimed-production-view [startDateTime]="startDateTime"></app-certimed-production-view>
    </as-split-area>-->
  
    <!-- IDENTIFICATION -->
    <!--<as-split-area size="30">
      <cdk-virtual-scroll-viewport itemSize="50" id="viewportDE"><app-nnidentification-folder [startDateTime]="startDateTime"></app-nnidentification-folder></cdk-virtual-scroll-viewport>
    </as-split-area>-->
  
  </as-split>
  <mat-spinner *ngIf="!isloaded"></mat-spinner>
  