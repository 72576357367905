import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ImageViewerComponent } from './image-viewer/image-viewer.component';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatGridListModule } from '@angular/material/grid-list';
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatButtonModule } from '@angular/material/button';
import { CertimedDataEntryViewerComponent } from './certimed-data-entry-viewer/certimed-data-entry-viewer.component';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { RentaDataEntryViewerComponent } from './renta-data-entry-viewer/renta-data-entry-viewer.component';
import { TextMaskModule } from 'angular2-text-mask';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { RentaDataEntrySearchModalComponent } from './renta-data-entry-search-modal/renta-data-entry-search-modal.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDividerModule } from '@angular/material/divider';
import { MatTableModule } from '@angular/material/table';
import { CertimedDataConflictViewerComponent } from './certimed-data-conflict-viewer/certimed-data-conflict-viewer.component';
import { RentaDataConflictViewerComponent } from './renta-data-conflict-viewer/renta-data-conflict-viewer.component';
import { CertimedDataEntrySearchModalComponent } from './certimed-data-entry-search-modal/certimed-data-entry-search-modal.component';
import { CertimedProductionViewComponent } from './certimed-production-view/certimed-production-view.component';
import { TextFieldModule } from '@angular/cdk/text-field';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BlockModalComponent } from './block-modal/block-modal.component';
import { NNIdentificationFolderComponent } from './nnidentification-folder/nnidentification-folder.component';
import { AngularSplitModule } from 'angular-split';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CertimedMensuraSearchModalComponent } from './certimed-mensura-search-modal/certimed-mensura-search-modal.component';
import { GHDataEntryViewerComponent } from './gh/gh-data-entry-viewer/gh-data-entry-viewer.component';
import { AgmCoreModule } from '@agm/core';
import { GHDataEntrySearchModalComponent } from './gh/gh-data-entry-search-modal/gh-data-entry-search-modal.component';
import { GHDataConflictViewerComponent } from './gh/gh-data-conflict-viewer/gh-data-conflict-viewer.component';
import { GHProductionViewComponent } from './gh/gh-production-view/gh-production-view.component';
import { GHProductionDataEntryViewerComponent } from './gh/gh-production-view/gh-production-data-entry-viewer/gh-production-data-entry-viewer.component';
import { GhCustomerReviewViewerComponent } from './gh/gh-customer-review-viewer/gh-customer-review-viewer.component';
import { StartComponent } from './start/start.component';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';
import { CookieService } from 'ngx-cookie-service';
import { TokenInterceptor } from './auth/services/token.interceptor';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    ImageViewerComponent,
    CertimedDataEntryViewerComponent,
    RentaDataEntryViewerComponent,
    RentaDataEntrySearchModalComponent,
    CertimedDataConflictViewerComponent,
    RentaDataConflictViewerComponent,
    CertimedDataEntrySearchModalComponent,
    CertimedProductionViewComponent,
    BlockModalComponent,
    NNIdentificationFolderComponent,
    CertimedMensuraSearchModalComponent,
    GHDataEntryViewerComponent,
    GHDataEntrySearchModalComponent,
    GHDataConflictViewerComponent,
    GHProductionViewComponent,
    GHProductionDataEntryViewerComponent,
    GhCustomerReviewViewerComponent,
    StartComponent,
    NotFoundPageComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MatGridListModule,
    FlexLayoutModule,
    MatToolbarModule,
    MatIconModule,
    ScrollingModule,
    MatButtonModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    MatNativeDateModule,
    MatDatepickerModule,
    TextMaskModule,
    MatSnackBarModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatDividerModule,
    MatTableModule,
    TextFieldModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    AngularSplitModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    AgmCoreModule.forRoot(  {
      apiKey: 'AIzaSyCUhL6zO6aJ3070MyNmn8v0RS17Q3JjQIw',
	    libraries: ["places"]
    }),
  ],
  entryComponents: [
    RentaDataEntrySearchModalComponent,
    CertimedDataEntrySearchModalComponent,
    CertimedMensuraSearchModalComponent,
    BlockModalComponent,
    GHDataEntrySearchModalComponent,

  ],
  providers: [
    CookieService, {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true}],
  bootstrap: [AppComponent]
})
export class AppModule { }
