<!-- This allow the 'npm run extract' detect this translation -->
<span style="display: none;">
    {{ 'requiredErrorMessage' | translate }}
    {{ 'patternErrorMessage' | translate }}
    {{ 'maxLengthErrorMessage' | translate }}
    {{ 'invalidDate' | translate }}
    {{ 'invalidInvoiceDate' | translate }}
    {{ 'invalidTreatmentDateFrom' | translate }}
    {{ 'invalidTreatmentDateTo' | translate }}
    {{ 'olderInvalidInvoiceDate' | translate }}
    {{ 'olderInvalidTreatmentDateFrom' | translate }}
    {{ 'olderInvalidTreatmentDateTo' | translate }}
</span>
<!-- ------------------------------------------------------- -->

<section class="mat-typography">
    <form #deform id="{{idForm}}" [formGroup]="dataEntryForm" (ngSubmit)="onSubmitDE1()" autocomplete="off">
        <div id="de1" formGroupName="de1">

            <mat-form-field id="de-documentType" appearance="outline" [hidden]="visibleFields.indexOf('documentType') == -1" [ngClass]="{'correctFieldBG correctFieldL correctFieldB': fieldsComparationStyle.documentType == 1, 'wrongFieldB': fieldsComparationStyle.documentType == 2}">
                <mat-label>{{'documentType' | translate}}</mat-label>
                <mat-select formControlName="documentType" (selectionChange)="onDocumentTypeChanged()" required>
                    <mat-option *ngFor="let option of documentTypeOptions" value="{{option  | uppercase}}"> {{ option }}</mat-option>
                </mat-select>
                <mat-error *ngIf="getDocumentType().invalid">{{getErrorMessageCode(getDocumentType()) | translate}}</mat-error>
            </mat-form-field>

            <mat-form-field id="de-documentQuality" appearance="outline" [hidden]="visibleFields.indexOf('documentQuality') == -1" [ngClass]="{'correctFieldBG correctFieldL correctFieldB': fieldsComparationStyle.documentQuality == 1, 'wrongFieldB': fieldsComparationStyle.documentQuality == 2}">
                <mat-label>{{'documentQuality' | translate}}</mat-label>
                <mat-select formControlName="documentQuality" required>
                    <mat-option *ngFor="let option of documentQualityOptions" value="{{option | uppercase}}"> {{ option | uppercase}}</mat-option>
                </mat-select>
                <mat-error *ngIf="getDocumentQuality().invalid">{{getErrorMessageCode(getDocumentQuality()) | translate}}</mat-error>
            </mat-form-field>

            <mat-form-field id="de-countryOfTreatment" appearance="outline" [hidden]="visibleFields.indexOf('countryOfTreatment') == -1" [ngClass]="{'correctFieldBG correctFieldL correctFieldB': fieldsComparationStyle.countryOfTreatment == 1, 'wrongFieldB': fieldsComparationStyle.countryOfTreatment == 2}">
                <mat-label>{{'countryOfTreatment' | translate}}</mat-label>
                <mat-select formControlName="countryOfTreatment" (selectionChange)="onCountryOfTreatmentChanged()" required>
                    <mat-option *ngFor="let option of countries | keyvalue" value="{{option.key | uppercase}}"> {{ option.value }}</mat-option>
                </mat-select>
                <mat-error *ngIf="getCountryOfTreatment().invalid">{{getErrorMessageCode(getCountryOfTreatment()) | translate}}</mat-error>
            </mat-form-field>

            <mat-form-field id="de-insuredPerson" appearance="outline" [hidden]="visibleFields.indexOf('insuredPerson') == -1" [ngClass]="{'correctFieldBG correctFieldL correctFieldB': fieldsComparationStyle.insuredPerson == 1, 'wrongFieldB': fieldsComparationStyle.insuredPerson == 2}">
                <mat-label>{{'insuredPerson' | translate}}</mat-label>
                <mat-select formControlName="insuredPerson" (selectionChange)="onInsuredPersonChanged()" required>
                    <mat-option *ngFor="let option of insuredPersonOptions" value="{{option | uppercase}}"> {{ option }}</mat-option>
                </mat-select>
                <mat-error *ngIf="getInsuredPerson().invalid">{{getErrorMessageCode(getInsuredPerson()) | translate}}</mat-error>
            </mat-form-field>

            <mat-form-field id="de-invoiceCurrency" appearance="outline" [hidden]="visibleFields.indexOf('invoiceCurrency') == -1" [ngClass]="{'correctFieldBG correctFieldL correctFieldB': fieldsComparationStyle.invoiceCurrency == 1, 'wrongFieldB': fieldsComparationStyle.invoiceCurrency == 2}">
                <mat-label>{{'invoiceCurrency' | translate}}</mat-label>
                <mat-select formControlName="invoiceCurrency" (selectionChange)="checkOcrMatch('invoiceCurrency')" required>
                    <mat-option *ngFor="let option of currencies | keyvalue" value="{{option.key | uppercase}}"> {{ option.value }}</mat-option>
                </mat-select>
                <mat-error *ngIf="getInvoiceCurrency().invalid">{{getErrorMessageCode(getInvoiceCurrency()) | translate}}</mat-error>
            </mat-form-field>
            
            <mat-form-field id="de-invoiceAmount" appearance="outline" [hidden]="visibleFields.indexOf('invoiceAmount') == -1" [ngClass]="{'correctFieldBG correctFieldL correctFieldB': fieldsComparationStyle.invoiceAmount == 1, 'wrongFieldB': fieldsComparationStyle.invoiceAmount == 2}">
                <mat-label>{{'invoiceAmount' | translate}}</mat-label>
                <input matInput formControlName="invoiceAmount" placeholder="0" (change)="checkOcrMatch('invoiceAmount', 'MATH')" required/>
                <mat-error *ngIf="getInvoiceAmount().invalid">{{getErrorMessageCode(getInvoiceAmount()) | translate}}</mat-error>
            </mat-form-field>

            <mat-form-field id="de-invoiceNumber" appearance="outline" [hidden]="visibleFields.indexOf('invoiceNumber') == -1" [ngClass]="{'correctFieldBG correctFieldL correctFieldB': fieldsComparationStyle.invoiceNumber == 1, 'wrongFieldB': fieldsComparationStyle.invoiceNumber == 2}">
                <mat-label>{{'invoiceNumber' | translate}}</mat-label>
                <input matInput formControlName="invoiceNumber" placeholder="00" (change)="checkOcrMatch('invoiceNumber')" required/>
                <mat-error *ngIf="getInvoiceNumber().invalid">{{getErrorMessageCode(getInvoiceNumber()) | translate}}</mat-error>
            </mat-form-field>
            
            <div id="dates" [ngClass]="{'errorDate': shouldShowDateError}" appearance="outline">
                
                <mat-form-field id="de-invoiceDate" appearance="outline" [hidden]="visibleFields.indexOf('invoiceDate') == -1" [ngClass]="{'correctFieldBG correctFieldL correctFieldB': fieldsComparationStyle.invoiceDate == 1, 'wrongFieldB': fieldsComparationStyle.invoiceDate == 2}">
                    <mat-label>{{'invoiceDate' | translate}} (DDMMYYYY)</mat-label>
                    <input matInput formControlName="invoiceDate" placeholder="DDMMYYYY" (change)="onInvoiceDateChanged()" required/>
                    <mat-error *ngIf="getInvoiceDate().invalid">{{getErrorMessageCode(getInvoiceDate()) | translate}}</mat-error>
                </mat-form-field>
                
                <mat-form-field id="de-treatmentDateFrom" appearance="outline" [hidden]="visibleFields.indexOf('treatmentDateFrom') == -1" [ngClass]="{'correctFieldBG correctFieldL correctFieldB': fieldsComparationStyle.treatmentDateFrom == 1, 'wrongFieldB': fieldsComparationStyle.treatmentDateFrom == 2}">
                    <mat-label>{{'treatmentDateFrom' | translate}} (DDMMYYYY)</mat-label>
                    <input matInput formControlName="treatmentDateFrom" placeholder="DDMMYYYY" (change)="onTreatmentDateFromChanged()" required/>
                    <mat-error *ngIf="getTreatmentDateFrom().invalid">{{getErrorMessageCode(getTreatmentDateFrom()) | translate}}</mat-error>
                </mat-form-field>

                <mat-form-field id="de-treatmentDateTo" appearance="outline" [hidden]="visibleFields.indexOf('treatmentDateTo') == -1" [ngClass]="{'correctFieldBG correctFieldL correctFieldB': fieldsComparationStyle.treatmentDateTo == 1, 'wrongFieldB': fieldsComparationStyle.treatmentDateTo == 2}">
                    <mat-label>{{'treatmentDateTo' | translate}} (DDMMYYYY)</mat-label>
                    <input matInput formControlName="treatmentDateTo" placeholder="DDMMYYYY" (change)="onTreatmentDateToChanged()" required/>
                    <mat-error *ngIf="getTreatmentDateTo().invalid">{{getErrorMessageCode(getTreatmentDateTo()) | translate}}</mat-error>
                </mat-form-field>
                
                <p id="pErrorFont" *ngIf="shouldShowDateError" class="mat-error ng-star-inserted"> {{ dateErrorMessage | translate }} </p>
            
            </div>
            
            <mat-divider [hidden]="visibleFields.indexOf('providerName') == -1"></mat-divider>
            
            <div id="searchButtonDiv" *ngIf="isFormEnabled && visibleFields.indexOf('providerCity') != -1 && !isDisplayingCustomerReview"> <!--if not need city, then not need search -->
                <button mat-button *ngIf="!isFilled" type="button" id="search" disabled="{{!isFormEnabled}}" (click)="openDialogSearch()"> {{'searchAddress' | translate}} </button>
            </div>

            <mat-form-field id="de-providerName" appearance="outline" [hidden]="visibleFields.indexOf('providerName') == -1" [ngClass]="{'correctFieldBG correctFieldL correctFieldB': fieldsComparationStyle.providerName == 1, 'wrongFieldB': fieldsComparationStyle.providerName == 2}">
                <mat-label>{{'providerName' | translate}}</mat-label>
                <input matInput formControlName="providerName" (change)="checkOcrMatch('providerName')" required/>
                <mat-error *ngIf="getProviderName().invalid">{{getErrorMessageCode(getProviderName()) | translate}}</mat-error>
            </mat-form-field>
            
            <mat-form-field id="de-providerCity" appearance="outline" [hidden]="visibleFields.indexOf('providerCity') == -1" [ngClass]="{'correctFieldBG correctFieldL correctFieldB': fieldsComparationStyle.providerCity == 1, 'wrongFieldB': fieldsComparationStyle.providerCity == 2}">
                <mat-label>{{'providerCity' | translate}}</mat-label>
                <input matInput formControlName="providerCity" (change)="checkOcrMatch('providerCity')"/>
                <mat-error *ngIf="getProviderCity().invalid">{{getErrorMessageCode(getProviderCity()) | translate}}</mat-error>
            </mat-form-field>
            
            <mat-form-field id="de-providerStreet" appearance="outline" [hidden]="visibleFields.indexOf('providerStreet') == -1" [ngClass]="{'correctFieldBG correctFieldL correctFieldB': fieldsComparationStyle.providerStreet == 1, 'wrongFieldB': fieldsComparationStyle.providerStreet == 2}">
                <mat-label>{{'providerStreet' | translate}}</mat-label>
                <input matInput formControlName="providerStreet" (change)="checkOcrMatch('providerStreet')" />
                <mat-error *ngIf="getProviderStreet().invalid">{{getErrorMessageCode(getProviderStreet()) | translate}}</mat-error>
            </mat-form-field>
            
            <mat-form-field id="de-providerPostalCode" appearance="outline" [hidden]="visibleFields.indexOf('providerPostalCode') == -1" [ngClass]="{'correctFieldBG correctFieldL correctFieldB': fieldsComparationStyle.providerPostalCode == 1, 'wrongFieldB': fieldsComparationStyle.providerPostalCode == 2}">
                <mat-label>{{'providerPostalCode' | translate}}</mat-label>
                <input matInput formControlName="providerPostalCode" (change)="checkOcrMatch('postalCode')" />
                <mat-error *ngIf="getProviderPostalCode().invalid">{{getErrorMessageCode(getProviderPostalCode()) | translate}}
                </mat-error>
            </mat-form-field>
            
            <mat-form-field id="de-providerHouseNo" appearance="outline" [hidden]="visibleFields.indexOf('providerHouseNo') == -1" [ngClass]="{'correctFieldBG correctFieldL correctFieldB': fieldsComparationStyle.providerHouseNo == 1, 'wrongFieldB': fieldsComparationStyle.providerHouseNo == 2}">
                <mat-label>{{'providerHouseNumber' | translate}}</mat-label>
                <input matInput formControlName="providerHouseNo" (change)="checkOcrMatch('providerHouseNo')" />
                <mat-error *ngIf="getProviderHouseNo().invalid">{{getErrorMessageCode(getProviderHouseNo()) | translate}}
                </mat-error>
            </mat-form-field>
            
            <mat-form-field id="de-providerCountry" appearance="outline" [hidden]="visibleFields.indexOf('providerCountry') == -1" [ngClass]="{'correctFieldBG correctFieldL correctFieldB': fieldsComparationStyle.providerCountry == 1, 'wrongFieldB': fieldsComparationStyle.providerCountry == 2}">
                <mat-label>{{'providerCountry' | translate}}</mat-label>
                <mat-select formControlName="providerCountry" (selectionChange)="onProviderCountryChanged()" required>
                    <mat-option *ngFor="let option of countries | keyvalue" value="{{option.key | uppercase}}"> {{ option.value }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="getProviderCountry().invalid">{{getErrorMessageCode(getProviderCountry()) | translate}}</mat-error>
            </mat-form-field>

            <mat-form-field id="de-providerCategory" appearance="outline" [hidden]="visibleFields.indexOf('providerCategory') == -1" [ngClass]="{'correctFieldBG correctFieldL correctFieldB': fieldsComparationStyle.providerCategory == 1, 'wrongFieldB': fieldsComparationStyle.providerCategory == 2}">
                <mat-label>{{'providerCategory' | translate}}</mat-label>
                    <input matInput formControlName="providerCategory" (change)="checkOcrMatch('providerCategory')" required/>
                <mat-error *ngIf="getProviderCategory().invalid">{{getErrorMessageCode(getProviderCategory()) | translate}}</mat-error>
            </mat-form-field>

            <mat-divider [hidden]="visibleFields.indexOf('diagnosis1') == -1"></mat-divider>

            <mat-form-field id="de-diagnosis1" appearance="outline" class="w-100" [hidden]="visibleFields.indexOf('diagnosis1') == -1" [ngClass]="{'correctFieldBG correctFieldL correctFieldB': fieldsComparationStyle.diagnosis1 == 1, 'wrongFieldB': fieldsComparationStyle.diagnosis1 == 2}">
                <mat-label>{{'diagnosis1' | translate}}</mat-label>
                <input matInput formControlName="diagnosis1" (change)="checkOcrMatch('diagnosis1')" required/>
                <mat-error *ngIf="getDiagnosis1().invalid">{{getErrorMessageCode(getDiagnosis1()) | translate}}
                </mat-error>
            </mat-form-field>

            <mat-form-field id="de-diagnosis2" appearance="outline" class="w-100" [hidden]="(visibleFields.indexOf('diagnosis2') == -1)" [ngClass]="{'correctFieldBG correctFieldL correctFieldB': fieldsComparationStyle.diagnosis2 == 1, 'wrongFieldB': fieldsComparationStyle.diagnosis2 == 2}">
                <mat-label>{{'diagnosis2' | translate}}</mat-label>
                <input matInput formControlName="diagnosis2" (change)="checkOcrMatch('diagnosis2')"/>
                <mat-error *ngIf="getDiagnosis2().invalid">{{getErrorMessageCode(getDiagnosis2()) | translate}}
                </mat-error>
            </mat-form-field>

            <mat-form-field id="de-diagnosis3" appearance="outline" class="w-100" [hidden]="(visibleFields.indexOf('diagnosis3') == -1)" [ngClass]="{'correctFieldBG correctFieldL correctFieldB': fieldsComparationStyle.diagnosis3 == 1, 'wrongFieldB': fieldsComparationStyle.diagnosis3 == 2}">
                <mat-label>{{'diagnosis3' | translate}}</mat-label>
                <input matInput formControlName="diagnosis3" (change)="checkOcrMatch('diagnosis3')"/>
                <mat-error *ngIf="getDiagnosis3().invalid">{{getErrorMessageCode(getDiagnosis3()) | translate}}
                </mat-error>
            </mat-form-field>

            <mat-form-field id="de-quantity" appearance="outline" [hidden]="visibleFields.indexOf('quantity') == -1" [ngClass]="{'correctFieldBG correctFieldL correctFieldB': fieldsComparationStyle.quantity == 1, 'wrongFieldB': fieldsComparationStyle.quantity == 2}">
                <mat-label>{{'quantity' | translate}}</mat-label>
                <input matInput formControlName="quantity" *ngIf="getDocumentType().value != 'OUTPATIENT'" (change)="checkOcrMatch('quantity')" required/>
                <mat-select formControlName="quantity" *ngIf="getDocumentType().value == 'OUTPATIENT'" (selectionChange)="checkOcrMatch('quantity')" required>
                    <mat-option value="1">1</mat-option>
                    <mat-option value="NA">NA</mat-option>
                </mat-select>
                <mat-error *ngIf="getQuantity().invalid">{{getErrorMessageCode(getQuantity()) | translate}}
                </mat-error>
            </mat-form-field>
        
        </div>

        <div id="cr" formGroupName="cr">
            <mat-form-field id="cr-remarks" appearance="outline" class="w-100" [hidden]="(visibleFields.indexOf('remarks') == -1)">
                <mat-label>{{'remarks' | translate}}</mat-label>
                <input matInput formControlName="remarks" />
            </mat-form-field>
        </div>
        <mat-divider></mat-divider>

        <div id="buttons">
            <button mat-raised-button id="save" type="submit" [disabled]="disableSend" *ngIf="showSend"> Save </button>
            <button mat-raised-button type="button" id="close" (click)="close()" [disabled]="disableClose" *ngIf="showClose"> Close </button>
            <button mat-raised-button type="button" id="sendCR" (click)="sendToCostumerReview()" [disabled]="disableCR" color="primary" *ngIf="showCR"> Send Customer Review</button>
        </div>

    </form>

</section>
