import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { CookieService } from "ngx-cookie-service";
import { AuthService } from "../auth/services/auth.service";
import { CommonInfoFolder } from "../Interfaces/commonInfoFolder";
import { CommonControllerService } from "../services/common-controller.service";

@Component({
  selector: "app-start",
  templateUrl: "./start.component.html",
  styleUrls: ["./start.component.css"],
})
export class StartComponent implements OnInit {
  folderHash: string;
  tagId: string;
  username: string = "";
  heightWindow: string;
  startDateTime: Date = new Date();
  commonInfo: CommonInfoFolder;

  showTraduction: boolean = false;
  isloaded: boolean = false;

  shouldDisplayDE: boolean = false;
  shouldDisplayDC: boolean = false;
  shouldDisplayCR: boolean = false;

  constructor(
    private commonControllerService: CommonControllerService,
    private translate: TranslateService,
    private authService: AuthService,
    private cookieService: CookieService
  ) {
    translate.setDefaultLang("en");
  }

  ngOnInit() {
    var url = new URL(window.location.href);
    var pathname = url.pathname;
    let route = pathname.split("/");
    pathname = route[1];
    this.folderHash = route[2];
    this.tagId = route[3];

    switch (pathname) {
      case "DataEntry":
        this.shouldDisplayDE = true;
        break;
      case "DataConflict":
        this.shouldDisplayDC = true;
        break;
      case "CustomerReview":
        this.shouldDisplayCR = true;
        break;
      default:
        break;
    }

    this.username = this.authService.getUsername();

    if (this.folderHash) {
      this.GetInfo(this.folderHash, this.tagId);
      var toolbarHeight = (
        document
          .getElementsByClassName("mat-toolbar-row")
          .item(0) as HTMLElement
      ).offsetHeight;
      this.heightWindow =
        document.documentElement.clientHeight - toolbarHeight + "px";
      if (this.isloaded) {
        document.getElementById("viewportDE").style.height = this.heightWindow;
      }
    } else {
      //SHOW SOMETHING THAT INDICATES AN ERROR
      console.log("ERROR: We don't have the folderHash");
    }
  }

  GetInfo(folderHash: string, tagId: string = null) {
    if (tagId && tagId != "" && tagId !== null) {
      this.commonControllerService
        .GetCommonInfo(folderHash, tagId)
        .then((result) => {
          this.commonInfo = result;
          this.commonInfo.userName = this.authService.getUserFullName();
          this.commonInfo.samName = this.authService.getUsername();
          console.log(this.commonInfo);
          this.startDateTime = new Date();
          if (this.commonInfo.project == "CERTIMED") this.showTraduction = true;
          if (this.commonInfo.company == "Fivesplash") {
            this.translate.use("fr");
          }

          this.isloaded = true;
        });
    }
  }

  useLanguage(language: string) {
    this.translate.use(language);
  }

  logout() {
    if (this.cookieService.check("token")) {
      this.cookieService.delete("token", "/");
      var location = window.location.href;
      var logOutWindow = window.open(
        "https://login.input4you.be/Login/Logout",
        "_blank"
      );

      setTimeout(() => {
        logOutWindow!.close();
        window.location.href = location;
      }, 100);
    }
  }
}
