import { Component, OnInit, Inject, NgZone, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar'
import { DataEntryControllerService } from '../../services/data-entry-controller.service';
import { GHServiceService } from '../services/gh-service.service';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { GHDCSearch, Origin } from '../interfaces/gh-dcinfo';
import { MapsAPILoader } from '@agm/core';
import { CommonControllerService } from 'src/app/services/common-controller.service';
import  providerKeywords  from '../providerKeywords.json';

@Component({
  selector: 'app-gh-data-entry-search-modal',
  templateUrl: './gh-data-entry-search-modal.component.html',
  styleUrls: ['./gh-data-entry-search-modal.component.css']
})

export class GHDataEntrySearchModalComponent implements OnInit {
  @ViewChild('searchTerm') searchTermRef: ElementRef;

  address: GHDCSearch = <GHDCSearch> {
                          city: '',
                          street_name: '',
                          street_number: '',
                          provider_name: '',
                          post_code: '',
                          origin: Origin.GOOGLE,
                        };
  manualInput: boolean;
  showNewAddressButton: boolean;
  formValidators: Validators = {'validators': [Validators.required, Validators.maxLength(100)], updateOn: 'blur'};
  providerCategoryOptions:  Array<string>;
  localSearch: boolean;
  ocr: string;
  ocrSimplified: string;
  suggestedCategory: string;
  prevFormStatus: any = null;
  ocrVisible: boolean = false;
  skipGoogleSearch: boolean = false;
  showManualInputButton: boolean = true;

  displayedColumns: string[] = ['providerName', 'providerAddress'];
  dataSource = new Array<GHDCSearch>();
  showResult: boolean = false;

  providerSearch = new FormGroup({
    providerSearch: new FormControl('', Validators.required),
  });

  search = new FormGroup({
    searchTerm: new FormControl(''),
  });

  addressSearch = new FormGroup({
    country: new FormControl('', this.formValidators),
    city: new FormControl('', {'validators': Validators.maxLength(100), updateOn: 'blur'}),
    street: new FormControl('', {'validators': Validators.maxLength(100), updateOn: 'blur'}),
    houseNo: new FormControl('', {'validators': Validators.maxLength(100), updateOn: 'blur'}),
    postalCode: new FormControl('', {'validators': Validators.maxLength(100), updateOn: 'blur'}),
    providerCategory: new FormControl('', this.formValidators),
    providerName: new FormControl('', this.formValidators),
  });

  constructor(public dialogRef: MatDialogRef<GHDataEntrySearchModalComponent>, @Inject(MAT_DIALOG_DATA) public data,
              private dataEntryControllerService: DataEntryControllerService, private notification: MatSnackBar,
              private mapsAPILoader: MapsAPILoader, private ngZone: NgZone, public ghService: GHServiceService, private commonControllerService: CommonControllerService) {

      
    this.address.provider_name = this.addressSearch.get('providerName').value;
    this.address.country = this.data.country;
    this.addressSearch.get('country').setValue(this.address.country);
    this.addressSearch.disable();
    this.addressSearch.get('providerName').enable();
    this.addressSearch.get('providerCategory').enable();
    this.providerCategoryOptions = this.getProviderCategory();
    this.ocrVisible = false;
    this.ocr = this.data.ocr;
    this.skipGoogleSearch = this.data.skipGoogleSearch;
    this.ocrSimplified = this.simplifyText(this.ocr);
    this.suggestCategory();
    }

  ngOnInit() {
    this.localSearch = true;
    this.manualInput = false;
    this.showNewAddressButton = false;
  }

  ngAfterViewInit(){
    this.findAddress();
  }

  getProviderCategory(){
    return this.data.categories;
  }
  
  suggestCategory(){
    for(var category in providerKeywords)
      for(var word in providerKeywords[category][this.address.country])
        if(this.ocr.toUpperCase().includes(word.toUpperCase())){
          this.suggestedCategory = category;
          this.addressSearch.get('providerCategory').setValue(this.suggestedCategory.toUpperCase());
          return true;
        }
    
    return false;
  }

  noticeOcrMatch(fieldKey: string){
    var field: AbstractControl = this.addressSearch.get(fieldKey);
    if (this.ocr && field != null && !field.invalid && field.value != null && field.value != '') {
      var isMatch: boolean = this.checkValueWithOcr(field.value);
      this.processMatchStyle(fieldKey, isMatch);
    } else if (field != null) {
      this.removeOcrStyle(fieldKey);
    }
  }

  checkValueWithOcr(value: string): boolean {
    value = this.simplifyText(value.toUpperCase());
    return this.ocrSimplified.includes(value);
  }

  simplifyText(text: string): string {
    text = text.split("\"").join(" ");
    text = text.split(/[^\w\s]/).join("");
    return text;
  }

  removeOcrStyle(key: string): void {
    key = 'sm-' + key;
    var element = document.getElementById(key);

    if (element) {
      if (element.classList.contains('ocrMismatch')) {
        element.classList.remove('ocrMismatch')
      }
      if (element.classList.contains('ocrMatch')) {
        element.classList.remove('ocrMatch');
      }
    } else {
      console.error("Cannot find the field element: ", key);
    }
  }

  processMatchStyle(key: string, match: boolean){
    key = 'sm-' + key;
    let element = document.getElementById(key);

    if(element){
      if(match){
        if(!element.classList.contains('ocrMatch'))
          element.classList.add('ocrMatch');
        
        if(element.classList.contains('ocrMismatch'))
          element.classList.remove('ocrMismatch')
      }
      else{
        if(!element.classList.contains('ocrMismatch'))
          element.classList.add('ocrMismatch');
      
        if(element.classList.contains('ocrMatch'))
          element.classList.remove('ocrMatch');
      }
    }
  }

  findAddress(){
    this.mapsAPILoader.load().then(() => {
      let autocomplete = new google.maps.places.Autocomplete(this.searchTermRef.nativeElement);

      // Restrictions and fields selection keep a small billing rate

      autocomplete.setFields(['address_components', 'name']);
      autocomplete.setComponentRestrictions({'country': this.address.country});

      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          console.log(place);
          this.address.street_number = place.address_components[0].long_name;
          this.address.street_name = place.address_components[1].long_name;
          this.address.city = place.address_components[3].long_name;
          this.address.provider_name = place.name;

          this.addressSearch.get('street').setValue(this.address.street_name);
          this.noticeOcrMatch('street');
          this.addressSearch.get('houseNo').setValue(this.address.street_number);
          this.noticeOcrMatch('houseNo');
          this.addressSearch.get('city').setValue(this.address.city);
          this.noticeOcrMatch('city');
          this.addressSearch.get('providerName').setValue(this.address.provider_name);
          this.noticeOcrMatch('providerName');

          if(this.address.post_code = place.address_components[place.address_components.length - 1].long_name) {
            if(!isNaN(parseInt(this.address.post_code))) {
              this.addressSearch.get('postalCode').setValue(this.address.post_code);
              this.noticeOcrMatch('postalCode');
            }
          }
        });
      });
    });
  }

  displayOcr(){
    let ocrWindowElement = document.getElementById('ocrWindow');
    if(!this.ocrVisible){
      this.ocrVisible = true;

      if(ocrWindowElement.innerHTML === '')
        ocrWindowElement.innerHTML = this.ocr;
    }
    else{
      this.ocrVisible = false;
    }
  }

  toggleManualInput(){
    this.manualInput = !this.manualInput;
    
    if(this.manualInput) {
      this.addressSearch.enable();
    } else {
      this.addressSearch.disable();
    }

    this.addressSearch.get('providerName').enable();
    this.addressSearch.get('providerCategory').enable();
  }

  save() {
    let cond: boolean = true;
    
    if(this.manualInput){
      this.address.street_number = this.addressSearch.get('houseNo').value;
      this.address.post_code = this.addressSearch.get('postalCode').value;
      this.address.street_name = this.addressSearch.get('street').value;
      this.address.provider_name = this.addressSearch.get('providerName').value;
      this.address.city = this.addressSearch.get('city').value;
      this.address.country = this.addressSearch.get('country').value;
      this.address.origin = Origin.BPO;
    }

    for(var key of Object.keys(this.address))
      if(!(key === 'post_code' ||  key === 'street_name' || key === 'street_number' || key === 'city')) {
        cond = cond && this.address[key] !== '';
        console.debug(key)
      }
    
    let providerCategory = this.addressSearch.get('providerCategory').value;

    cond = cond && providerCategory !== '';

    if(cond){
      let output = [this.address, this.addressSearch.get('providerCategory').value];
      this.dialogRef.close(output);
    } else{
      this.notification.open("Please, make sure all fields are filled", "", { duration: 1500 });
    }
  }

  doubleClick(value: GHDCSearch) {
    let output: GHDCSearch = <GHDCSearch>{};
    
    for(var key of Object.keys(value))
      if(value[key] === "" && (key == "country" || "provider_name") &&  this.localSearch){
        this.localSearch = false;
        this.search.get('searchTerm').setValue(value['provider_name']);
        this.notification.open("There is some missing information. Please, search again for the address and complete every field", "", { duration: 2500 });
      }
        

    if(this.localSearch){
      output.city = value.city;
      output.country = value.country_code;
      output.street_number = value.street_number;
      output.post_code = value.post_code;
      output.street_name = value.street_name;
      output.provider_name = value.provider_name;
      output.origin = Origin.COSTUMER;
      this.dialogRef.close([output, value.provider_type]);
    }
  }

  async onSubmit(result){
    this.showResult = true;
    if (result.providerSearch === "") {
      this.notification.open("Invalid search, empty input", "", { duration: 1500 });
      this.dataSource = new Array<GHDCSearch>();
    }
    else {
      var result = await this.ghService.SearchProviderData(result.providerSearch, this.address.country);
      this.dataSource = result;
   
      if (this.dataSource.length <= 0) {
        this.notification.open("No results found", "", { duration: 1500 });
        this.showNewAddressButton = true;
      }
    }
  }

  onClickNewAddress()
  {
    this.showNewAddressButton = false;
    this.localSearch = false;
    if(this.skipGoogleSearch)
    {
      this.showManualInputButton = false;
      this.toggleManualInput();
    }
  }

}
