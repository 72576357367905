import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { CommonControllerService } from "./services/common-controller.service";
import { CommonInfoFolder } from "./Interfaces/commonInfoFolder";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";
import { CookieService } from "ngx-cookie-service";
import { AuthService } from "./auth/services/auth.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  folderHash: string;
  username: string = "";
  heightWindow: string;
  startDateTime: Date;
  commonInfo: CommonInfoFolder;

  showTraduction: boolean = false;
  isloaded: boolean = false;

  pathname: string = "";
  validURL: boolean = true;

  loginSuccess = false;
  url: URL;

  constructor(
    private commonControllerService: CommonControllerService,
    private translate: TranslateService,
    private router: Router,
    private auth: AuthService,
    private cookieService: CookieService,
    private route: ActivatedRoute
  ) {
    translate.setDefaultLang("nl");
  }

  ngOnInit() {
    if (this.auth.isAuthenticated()) {
      this.loginSuccess = true;
    } else this.routerEvents();
  }

  routerEvents() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(async (event) => {
        if (event instanceof NavigationEnd) {
          if (
            !this.cookieService.check("token") ||
            !this.auth.isAuthenticated()
          ) {
            let token: string = "";
            let domain = this.getDomain();
            let urlToken = this.route.snapshot.queryParamMap.get("token") || "";

            //There is no cookie
            if (!this.cookieService.check("token")) {
              token = urlToken;
            }
            //There is cookie
            else {
              if (
                urlToken !== "" &&
                urlToken !== this.cookieService.get("token")
              )
                token = urlToken;
              else token = this.cookieService.get("token");
            }

            if (token != null && token != undefined && token != "") {
              await this.auth.authorize(token).then((data) => {
                if (data.result == "Success") {
                  this.loginSuccess = true;

                  //Set or update cookie
                  this.setCookie(token);

                  //Remove token from url
                  this.removeToken();
                } else {
                  //Redirect to login
                  this.auth.login(domain);
                }
              });
            } else {
              //Redirect to login
              this.auth.login(domain);
            }
          }
        }
      });
  }

  setCookie(token: string) {
    this.cookieService.set(
      "token",
      token,
      1, // Expires after 1 day
      "/",
      "",
      true,
      "None"
    );
  }

  removeToken() {
    if (this.cookieService.check("token")) {
      this.router.navigate([], {
        queryParams: {
          token: null,
        },
        queryParamsHandling: "merge",
      });
    }
  }

  getDomain() {
    return window.location.href;
  }
}
