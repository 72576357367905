import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { ImagesVM, ImageFS } from '../Interfaces/images';

@Injectable({
  providedIn: 'root'
})
export class FileServiceService {
  
  apiUrl: string = environment.fileServiceURL;
  listImages: ImagesVM[];
  constructor(private http: HttpClient) { }

  getBinaryImage(hash: string):string
  {
    return this.apiUrl + '/Image/GetBinaryFromDB?hash=' + hash + '&maxSize=0&' + new Date().getMilliseconds().toString();
  }

  rotateImageHash(hash: string, index: number)
  {
    return this.apiUrl + '/Image/GetBinaryRotatedFromDB?hash=' + hash + '&index=' + index + '&maxSize=0&' + new Date().getMilliseconds().toString();

  }

  changeColor(hash: string)
  {
    return this.apiUrl + '/Image/GetBinaryColorInvertedFromDB?hash=' + hash + '&maxSize=0&' + new Date().getMilliseconds().toString();
  }

  updateImages(listImages: ImagesVM[]) {
    this.listImages = listImages;
  }
}
